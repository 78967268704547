/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import styled from 'styled-components';
import { CnIcon } from '@/components/cn-icon';
import { collectNodes, isLeafNode, layeredSort, mergeCellProps, safeGetValue, safeGetCellProps, safeRenderHeader, } from '../utils';
function SortIcon({ size = 32, style, className, order, onClick, }) {
    return (
    // <svg
    //   style={style}
    //   className={className}
    //   focusable='false'
    //   preserveAspectRatio='xMidYMid meet'
    //   width={size}
    //   height={size}
    //   viewBox='0 0 32 32'
    //   aria-hidden='true'
    //   onClick={onClick}
    // >
    //   <path
    //     fill={order === 'asc' ? '#23A3FF' : '#bfbfbf'}
    //     transform='translate(0, 4)'
    //     d='M8 8L16 0 24 8z'
    //   />
    //   <path
    //     fill={order === 'desc' ? '#23A3FF' : '#bfbfbf'}
    //     transform='translate(0, -4)'
    //     d='M24 24L16 32 8 24z '
    //   />
    // </svg>
    React.createElement(React.Fragment, null,
        order && React.createElement(CnIcon, { type: "triangle-sort", size: "medium" }),
        order === 'asc' && React.createElement(CnIcon, { type: "triangle-up-fill", size: "medium" }),
        order === 'desc' && React.createElement(CnIcon, { type: "triangle-down-fill", size: "medium" })));
}
function DefaultSortHeaderCell({ children, column, onToggle, sortOrder, sortIndex, sortOptions, clickArea, }) {
    // 通过 justify-content 来与 col.align 保持对齐方向一致
    const justifyContent = column.align === 'right'
        ? 'flex-end'
        : column.align === 'center'
            ? 'center'
            : 'flex-start';
    return (React.createElement(TableHeaderCell, { onClick: clickArea === 'content' ? onToggle : undefined, style: {
            justifyContent,
            cursor: clickArea === 'content' ? 'pointer' : undefined,
        } },
        children,
        React.createElement(SortIcon, { onClick: clickArea === 'icon' ? onToggle : undefined, style: {
                userSelect: 'none',
                marginLeft: 2,
                flexShrink: 0,
                cursor: clickArea === 'icon' ? 'pointer' : undefined,
            }, size: 16, order: sortOrder }),
        sortOptions.mode === 'multiple' && sortIndex != -1 && (React.createElement("div", { style: {
                userSelect: 'none',
                marginLeft: 2,
                color: '#666',
                flex: '0 0 auto',
                fontSize: 10,
                fontFamily: 'monospace',
            } }, sortIndex + 1))));
}
function hasAnySortableColumns(cols) {
    return cols.some((col) => {
        var _a;
        return Boolean((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) ||
            (!isLeafNode(col) && hasAnySortableColumns((col === null || col === void 0 ? void 0 : col.children) || []));
    });
}
const TableHeaderCell = styled.div `
  display: flex;
  align-items: center;
`;
const stateKey = 'sort';
export default function sort(opts = {
    SortHeaderIconWidth: 16,
}) {
    return function sortStep(pipeline) {
        var _a, _b, _c;
        const { orders = ['desc', 'asc', 'none'], mode = 'multiple', SortHeaderCell = DefaultSortHeaderCell, keepDataSource, highlightColumnWhenActive, stopClickEventPropagation, clickArea = 'content', SortHeaderIconWidth = 16, } = opts;
        const inputSorts = (_c = (_b = (_a = opts.sorts) !== null && _a !== void 0 ? _a : pipeline.getStateAtKey(stateKey)) !== null && _b !== void 0 ? _b : opts.defaultSorts) !== null && _c !== void 0 ? _c : [];
        const activeSorts = inputSorts.filter((s) => s.order !== 'none');
        // 单字段排序的情况下 sorts 中只有第一个排序字段才会生效
        const sorts = mode === 'multiple' ? activeSorts : activeSorts.slice(0, 1);
        const onChangeSortsInMultipleMode = (nextSorts) => {
            var _a;
            (_a = opts.onChangeSorts) === null || _a === void 0 ? void 0 : _a.call(opts, nextSorts);
            pipeline.setStateAtKey(stateKey, nextSorts);
        };
        const onChangeSorts = mode === 'multiple'
            ? onChangeSortsInMultipleMode
            : (nextSorts) => {
                // 单字段排序的情况下，nextSorts 中只有最后一个排序字段才会生效
                const len = nextSorts.length;
                onChangeSortsInMultipleMode(nextSorts.slice(len - 1));
            };
        const sortOptions = {
            sorts,
            onChangeSorts,
            orders,
            mode,
            keepDataSource,
            highlightColumnWhenActive,
            stopClickEventPropagation,
            clickArea,
        };
        const sortMap = new Map(sorts.map((sort, index) => [sort.code, { index, ...sort }]));
        const dataSource = pipeline.getDataSource();
        const columns = pipeline.getColumns();
        pipeline.dataSource(processDataSource(dataSource));
        pipeline.columns(processColumns(columns));
        return pipeline;
        function processDataSource(dataSource) {
            if (keepDataSource) {
                return dataSource;
            }
            const sortColumnsMap = new Map(collectNodes(columns, 'leaf-only')
                .filter((col) => {
                var _a, _b;
                return ((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) !== false &&
                    ((_b = col.features) === null || _b === void 0 ? void 0 : _b.sortable) != null;
            })
                .map((col) => [col.code, col]));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return layeredSort(dataSource, (x, y) => {
                for (const { code, order } of sorts) {
                    const column = sortColumnsMap.get(code);
                    // 如果 code 对应的 column 不可排序，我们跳过该 code
                    if (column == null) {
                        continue;
                    }
                    const { sortable } = column.features;
                    const compareFn = typeof sortable === 'function' ? sortable : smartCompare;
                    const xValue = safeGetValue(column, x, -1);
                    const yValue = safeGetValue(column, y, -1);
                    const cmp = compareFn(xValue, yValue, x, y);
                    if (cmp !== 0) {
                        return cmp * (order === 'asc' ? 1 : -1);
                    }
                }
                return 0;
            });
        }
        // 在「升序 - 降序 - 不排序」之间不断切换
        function toggle(code) {
            const sort = sortMap.get(code);
            if (sort == null) {
                onChangeSorts(sorts.concat([{ code, order: orders[0] }]));
            }
            else {
                const index = sorts.findIndex((s) => s.code === code);
                const nextSorts = sorts.slice(0, index + 1);
                const nextOrder = getNextOrder(sort.order);
                if (nextOrder === 'none') {
                    nextSorts.pop();
                }
                else {
                    nextSorts[index] = { ...nextSorts[index], order: nextOrder };
                }
                onChangeSorts(nextSorts);
            }
        }
        function processColumns(columns) {
            return columns.map(dfs);
            function dfs(col) {
                var _a;
                const result = { ...col };
                const sortable = col.code && (((_a = col.features) === null || _a === void 0 ? void 0 : _a.sortable) || sortMap.has(col.code));
                const active = sortable && sortMap.has(col === null || col === void 0 ? void 0 : col.code);
                if (sortable) {
                    let sortIndex = -1;
                    let sortOrder = 'none';
                    if (active) {
                        const { order, index } = sortMap.get(col === null || col === void 0 ? void 0 : col.code) || {};
                        sortOrder = order;
                        sortIndex = index;
                        if (highlightColumnWhenActive) {
                            // @ts-ignore
                            result.headerCellProps = mergeCellProps(col.headerCellProps, {
                                style: { background: 'var(--header-highlight-bgcolor)' },
                            });
                            result.getCellProps = (value, row, rowIndex) => {
                                const prevCellProps = safeGetCellProps(col, row, rowIndex);
                                return mergeCellProps(prevCellProps, {
                                    style: { background: 'var(--highlight-bgcolor)' },
                                });
                            };
                        }
                    }
                    result.title = (React.createElement(SortHeaderCell, { clickArea: clickArea, onToggle: (e) => {
                            if (stopClickEventPropagation) {
                                e.stopPropagation();
                            }
                            toggle(col === null || col === void 0 ? void 0 : col.code);
                        }, sortOrder: sortOrder, column: col, sortIndex: sortIndex, 
                        // @ts-ignore
                        sortOptions: sortOptions }, safeRenderHeader(col)));
                    // @ts-ignore
                    if ((result === null || result === void 0 ? void 0 : result.width) > 0) {
                        // @ts-ignore
                        result.width += SortHeaderIconWidth + 2;
                    }
                }
                if (!isLeafNode(col)) {
                    result.children = col.children.map(dfs);
                }
                return result;
            }
        }
        function getNextOrder(order) {
            const idx = orders.indexOf(order);
            return orders[idx === orders.length - 1 ? 0 : idx + 1];
        }
    };
}
function smartCompare(x, y) {
    // 将 null 排在最后面
    if (x == null) {
        return 1;
    }
    if (y == null) {
        return -1;
    }
    if (typeof x === 'number' && typeof y === 'number') {
        return x - y;
    }
    if (typeof x === 'string' && typeof y === 'string') {
        // 字符串使用 默认的字典序
        if (x < y) {
            return -1;
        }
        else if (x > y) {
            return 1;
        }
        else {
            return 0;
        }
    }
    if (Array.isArray(x) && Array.isArray(y)) {
        const len = Math.min(x.length, y.length);
        for (let i = 0; i < len; i++) {
            const cmp = smartCompare(x[i], y[i]);
            if (cmp !== 0) {
                return cmp;
            }
        }
        // 数组长度不等时，元素少的字段放在前面
        return x.length - y.length;
    }
    // 对于不认识的数据类型，返回 0
    return 0;
}
