/* eslint-disable @typescript-eslint/no-shadow */
import $i18n from 'panda-i18n';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import * as React from 'react';
import BaseTable, { DropDown, copyText, } from '@/components/cn-base-table';
import { Pagination } from '@/components/fusion';
// import { PaginationProps } from '@fusion/types/pagination';
import classnames from 'classnames';
import { useLatest } from 'ahooks';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import flip from 'lodash/flip';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
// import { ICnColumn } from '@/components/cn-base-table/type';
import Toolbar from './toolbar';
import { useUnitValue, useControlValue, useControlColumnsValue, usePage, useColumnsResize, } from './hooks';
import fetch from './fetch';
import Config, { config, getCellFormat, registerFormat } from './config';
import GlobalContext from './context';
import './index.scss';
import { isString, getTransform, isNumber } from './util';
import { safeCallFunction, useSizeChange } from '@/components/cn-utils';
// export type { CnTableProps } from '../type';
const { useEffect, useMemo, useState, useRef, useCallback } = React;
// export CNTableProps;
// interface tableSlot {
//   centerInToolAndTable: React.ReactNode;
//   centerInPageAndTable: React.ReactNode;
// }
// export interface CnTableProps extends BaseTablePropsI {
//   /**
//    * 远程配置
//    */
//   remote?: IRemote;
//   /**
//    * 格式化配置
//    */
//   format?: formatMap;
//   /**
//    * 最高高度配置。
//    */
//   maxHeight?: number;
//   /**
//    * 容器自由拉升，且分页固定在底层
//    */
//   fullContainer?: boolean;
//   /**
//    * 分页开启
//    */
//   paging?: boolean;
//   pagingDirection: 'left' | 'right';
//   /**
//    * 分页props
//    */
//   pageProps: PaginationProps;
//   /**
//    * 分页size列表
//    */
//   pageSizeList: number[];
//   /**
//    * toolbar 属性
//    */
//   toolbar: IToolbar;
//   /**
//    * 显示选择列
//    */
//   showSelect: boolean;
//   /**
//    * 选择列类型配置
//    */
//   selectType?: 'single';
//   /**
//    * 是否开启loading
//    */
//   loading: boolean;
//   /**
//    * 单元格内容省略模式开启
//    */
//   cellOverflowEllipsis:
//   | ((
//     rowIndex: number,
//     colIndex: number,
//     key: string,
//     rowData: Obj,
//   ) => React.ReactNode)
//   | boolean;
//   /**
//    * 单元格是否允许复制
//    */
//   cellCopyEnable: boolean;
//   /**
//    * 分页变化监听
//    */
//   onPageChange: (pageProps: Obj) => void;
//   /**
//    * 选择变化监听
//    */
//   onSelectChange: (keys: string[], record: ICnTableColumn[]) => void;
//   /**
//    * loading变化监听
//    */
//   loadingChange: (loading: boolean) => void;
//   /**
//    * 插槽
//    */
//   slot?: tableSlot;
//   /**
//    * 外部容器class
//    */
//   className: string;
//   /**
//    * 表格区域class
//    */
//   tableClassName: string;
//   /**
//    * 满屏受控
//    */
//   isFullScreen?: boolean;
//   /**
//    * 满屏层级
//    */
//   fullScreenZIndex?: number;
//   size: 'large' | 'small' | 'medium';
//   defaultIsFullScreen: boolean;
//   defaultSize?: 'large' | 'small' | 'medium';
//   defaultIsZebra?: boolean;
//   defaultPageSize?: number;
//   defaultCurrentPage?: number;
//   defaultSorts: SortItem[];
// }
function CNTable(props) {
    var _a;
    const { showSelect = false, selectType, remote, maxHeight, paging = false, pagingDirection = 'right', pageProps, onPageChange, onSelectChange, toolbar, fullContainer = true, rowSelection, columnsAutoWidth = true, onSort, sortCustom, tree, emptyContent, cellOverflowEllipsis = false, slot, className, tableClassName, defaultPageSize = Config.defaultPageSize, format, ...rest } = normalizeProps(props);
    const global = useMemo(() => {
        return {
            tableProps: props,
        };
    }, []);
    const delegateHub = useDelegateHub(props);
    const { dataSource, setDataSource, primaryKey, setPrimaryKey, sorts, setSorts, loading, setLoading, size, setSize, fullScreen, setFullScreen, zebra, setZebra, columns, setColumns, } = delegateHub;
    const columnsResize = useColumnsResize(props, columns, setColumns);
    rest.columnResize = columnsResize;
    const { hasSelect, storeSelectInfo, selectKeys, setSelectKeys } = useSelect(props);
    const { treeConfig, treeKeys, setTreeKeys } = useGetTreeConfig(props, setLoading);
    const pageRenderInfo = usePage(props);
    // 处理滚动穿透问题、并记录滚动距离。
    useScrollRecord(fullScreen);
    // 保存排序等内置参 数
    const innerParams = useRef({});
    const clear = () => {
        // @ts-ignore
        setSelectKeys([], []);
        setSorts([]);
        innerParams.current = {};
        setTreeKeys([]);
    };
    const fetchCall = useRemote({
        pageRenderInfo,
        setLoading,
        remote,
        innerParams,
        innerInfo: Object.assign({}, delegateHub, {
            selectKeys,
            setSelectKeys,
            treeKeys,
            setTreeKeys,
            pageInfo: pageRenderInfo,
            selectInfo: storeSelectInfo,
        }),
    }, (result, shouldClearData) => {
        batchedUpdates(() => {
            var _a, _b;
            isFunction(remote === null || remote === void 0 ? void 0 : remote.dataChange) && (remote === null || remote === void 0 ? void 0 : remote.dataChange(result));
            const { tableColumns: sourceTableColumns, tableData, paging, primaryKey, } = result || {};
            let tableColumns = sourceTableColumns;
            // @ts-ignore
            const hasRemoteColumns = isEmpty(props.columns) && !isEmpty(tableColumns);
            if (hasRemoteColumns) {
                setColumns(tableColumns);
            }
            const emptyColumns = !props.columns &&
                isEmpty(tableColumns) &&
                isPlainObject(tableData === null || tableData === void 0 ? void 0 : tableData[0]);
            if (emptyColumns) {
                // @ts-ignore
                const data = ((tableData === null || tableData === void 0 ? void 0 : tableData[0]) || {});
                tableColumns = Object.keys(data)
                    .filter((key) => isString(data[key]) || isNumber(data[key]))
                    .map((key) => ({
                    name: key,
                    dataIndex: key,
                }));
                setColumns(tableColumns);
            }
            if (Array.isArray(tableData)) {
                setDataSource(tableData);
            }
            else {
                setDataSource([]);
            }
            const total = (_b = (_a = paging === null || paging === void 0 ? void 0 : paging.totalCount) !== null && _a !== void 0 ? _a : paging === null || paging === void 0 ? void 0 : paging.total) !== null && _b !== void 0 ? _b : 0;
            if (!isNil(total)) {
                pageRenderInfo.setTotal(total);
            }
            if (!isNil(paging === null || paging === void 0 ? void 0 : paging.pageSize) &&
                (paging === null || paging === void 0 ? void 0 : paging.pageSize) !== pageRenderInfo.pageSize) {
                pageRenderInfo.setPageSize(paging === null || paging === void 0 ? void 0 : paging.pageSize);
            }
            if (shouldClearData) {
                // if (pageRenderInfo.currentPage !== 1) {
                pageRenderInfo.setCurrent(1);
                // }
                // 分页重置，正常情况下是需要重新刷新接口，但是这里不需要，所以加上一个锁
                // pageRenderInfo.doLock();
                clear(); // 清楚若干状态
            }
            if (isString(primaryKey)) {
                // @ts-ignore
                setPrimaryKey(paging === null || paging === void 0 ? void 0 : paging.primaryKey);
            }
        });
    });
    const middleSort = function (code, order, sort) {
        var _a;
        // @ts-ignore
        setSorts(code && order ? [{ code, order }] : []);
        if (isFunction(onSort)) {
            // @ts-ignore
            return onSort(code, order, sort);
        }
        const asciiSort = (_a = remote === null || remote === void 0 ? void 0 : remote.asciiSort) !== null && _a !== void 0 ? _a : true;
        if (!asciiSort) {
            innerParams.current.sortList = [{ code, order }];
            fetchCall(false);
        }
        return !!asciiSort;
    };
    // 扩展
    const cellProps = expandCell(props);
    // 执行覆盖操作
    const mergedColumns = handleMerge(props, columns);
    // format
    const formatColumns = cellFormat(mergedColumns, format);
    setInnerInfo(remote === null || remote === void 0 ? void 0 : remote.instance, Object.assign({}, delegateHub, {
        selectKeys,
        setSelectKeys,
        treeKeys,
        columns: formatColumns,
        setTreeKeys,
        dataSource,
        pageInfo: pageRenderInfo,
        selectInfo: storeSelectInfo,
    }));
    return (React.createElement(GlobalContext.Provider, { value: global },
        React.createElement("div", { "data-name": "CnTable", className: classnames('cn-table', `cn-table-${size}`, className, {
                'cn-table-full': fullContainer || (!fullContainer && paging),
                'cn-table-fullscreen': fullScreen,
                'cn-ui-table': true,
            }), style: getWrapperStyle(props) },
            isFunction(slot === null || slot === void 0 ? void 0 : slot.statisticsInfo) && (React.createElement("div", { style: { marginBottom: '8px', marginTop: '8px' } }, slot === null || slot === void 0 ? void 0 : slot.statisticsInfo(selectKeys, (_a = storeSelectInfo.current[1]) !== null && _a !== void 0 ? _a : [], dataSource, Object.assign({}, delegateHub, {
                selectKeys,
                setSelectKeys,
                treeKeys,
                columns: formatColumns,
                setTreeKeys,
                dataSource,
                pageInfo: pageRenderInfo,
                selectInfo: storeSelectInfo,
            })))),
            toolbar && (React.createElement(Toolbar, { ...toolbar, tableInfo: {
                    selectKeys,
                    storeSelectInfo,
                    showSelectedNum: hasSelect && selectType !== 'single',
                    size,
                    setSize,
                    fullScreen,
                    setFullScreen,
                    zebra,
                    setZebra,
                    columns,
                    setColumns,
                    totalSlot: slot === null || slot === void 0 ? void 0 : slot.totalInfo,
                    // total: (pageProps?.total ?? pageRenderInfo.total) as number,
                    total: pageRenderInfo.total,
                } })), slot === null || slot === void 0 ? void 0 :
            slot.centerInToolAndTable,
            React.createElement(BaseTable, { ...rest, locale: {
                    operateColumnTitle: $i18n.get({
                        id: '31255239218827264.CNTM',
                        dm: '操作',
                        ns: 'CnTable',
                    }),
                    operateMoreText: $i18n.get({
                        id: 'More',
                        dm: '更多',
                        ns: 'CnTable',
                    }),
                    copyColumnText: $i18n.get({
                        id: 'WholeColumnReplication',
                        dm: '整列复制',
                        ns: 'CnTable',
                    }),
                    copySelectText: $i18n.get({
                        id: 'CopyTheSelectedColumn',
                        dm: '复制选中列',
                        ns: 'CnTable',
                    }),
                    copyTableText: $i18n.get({
                        id: 'CopyTable',
                        dm: '复制表格',
                        ns: 'CnTable',
                    }),
                }, size: size, style: getFinalStyle(props), columns: formatColumns, dataSource: dataSource, primaryKey: primaryKey, isLoading: loading, columnsAutoWidth: columnsAutoWidth, onSort: middleSort, className: tableClassName, sortCustom: {
                    ...(sortCustom || {}),
                    sorts,
                    onChangeSorts: setSorts,
                }, isZebra: zebra, cellProps: cellProps, emptyContent: emptyContent !== null && emptyContent !== void 0 ? emptyContent : $i18n.get({
                    id: 'TableDataIsEmpty',
                    dm: '表格数据为空',
                    ns: 'CnTable',
                }), treeConfig: treeConfig, rowSelection: hasSelect
                    ? {
                        type: selectType,
                        ...(rowSelection || {}),
                        value: selectKeys,
                        selectedRowKeys: selectKeys,
                        onChange: setSelectKeys,
                    }
                    : undefined }),
            React.createElement("div", { className: classnames('cn-table-footer-slot', {
                    'cn-table-footer-slot-display': !paging && !(slot === null || slot === void 0 ? void 0 : slot.centerInPageAndTable),
                }) },
                paging ? (React.createElement("div", { style: { textAlign: pagingDirection }, className: "cn-table-page" },
                    React.createElement(Pagination, { size: size, pageSizeSelector: "dropdown", current: pageRenderInfo.currentPage, pageSize: pageRenderInfo.pageSize, onChange: (page) => {
                            pageRenderInfo.setCurrent(page);
                            fetchCall({ currentPage: page });
                        }, onPageSizeChange: (pageSize) => {
                            pageRenderInfo.setPageSize(pageSize);
                            fetchCall({ currentPage: 1, pageSize });
                        }, total: pageRenderInfo.total, pageSizeList: pageRenderInfo.pageSizeList, ...(pageProps || {}) }))) : null, slot === null || slot === void 0 ? void 0 :
                slot.centerInPageAndTable))));
}
function normalizeProps(props) {
    var _a, _b, _c;
    normalRemote(props);
    if (!isEmpty((_a = props === null || props === void 0 ? void 0 : props.toolbar) === null || _a === void 0 ? void 0 : _a.batchArea)) {
        props.toolbar.batchArea = normalizeDisabledAndHidden(props.toolbar.batchArea);
    }
    if (!isEmpty((_b = props === null || props === void 0 ? void 0 : props.toolbar) === null || _b === void 0 ? void 0 : _b.toolArea)) {
        props.toolbar.toolArea = normalizeDisabledAndHidden(props.toolbar.toolArea);
    }
    if (!isEmpty((_c = props === null || props === void 0 ? void 0 : props.operateColumn) === null || _c === void 0 ? void 0 : _c.buttons)) {
        props.operateColumn.buttons = normalizeDisabledAndHidden(props.operateColumn.buttons);
    }
    return props;
}
function normalizeDisabledAndHidden(areList) {
    if (!Array.isArray(areList))
        return [];
    const normalizeAreList = areList.map((item) => {
        if (isFunction(item === null || item === void 0 ? void 0 : item.hidden) && !isFunction(item === null || item === void 0 ? void 0 : item.visible)) {
            item.visible = (...args) => {
                return !item.hidden(...args);
            };
        }
        if (isFunction(item === null || item === void 0 ? void 0 : item.disabled) && !isFunction(item === null || item === void 0 ? void 0 : item.isDisabled)) {
            item.isDisabled = item.disabled;
        }
        return item;
    });
    return normalizeAreList;
    // ['toolbar.batchArea', 'toolbar.toolArea', 'operateColumn.buttons'].forEach((path) => {
    //   const list = getValueByPath(props as unknown as Obj, path.split('.'), []);
    //   Array.isArray(list) && list.forEach((item) => {
    //     if (isPlainObject(item)) {
    //       if (isFunction(item.hidden) && !isFunction(item.visible)) {
    //         item.visible = (...args) => {
    //           return !item.hidden(...args);
    //         };
    //       }
    //       if (isFunction(item.disabled) && !isFunction(item.isDisabled)) {
    //         item.isDisabled = item.disabled;
    //       }
    //     }
    //   });
    // });
}
function normalRemote(props) {
    if (isPlainObject(props === null || props === void 0 ? void 0 : props.remote)) {
        if (props.remote.service && !props.remote.fetch) {
            props.remote.fetch = props.remote.service;
        }
        if ('manual' in props.remote && !('immediate' in props.remote)) {
            props.remote.immediate = !props.remote.manual;
        }
        if (isFunction(props.remote.onBefore) &&
            !isFunction(props.remote.paramsTransform)) {
            props.remote.paramsTransform = props.remote.onBefore;
        }
        if (isFunction(props.remote.onError) &&
            !isFunction(props.remote.failCallback)) {
            props.remote.failCallback = props.remote.onError;
        }
        if (isFunction(props.remote.onSuccess) &&
            !isFunction(props.remote.responseTransform)) {
            props.remote.responseTransform = props.remote.onSuccess;
        }
    }
}
function handleMerge(props, columns) {
    const { remote } = props;
    if ('columns' in props && !isEmpty(props.columns)) {
        return columns;
    }
    const coverColumns = remote === null || remote === void 0 ? void 0 : remote.columns;
    if (Array.isArray(coverColumns)) {
        coverColumns.forEach((item) => {
            const key = (item === null || item === void 0 ? void 0 : item.dataIndex) || (item === null || item === void 0 ? void 0 : item.key);
            columns.forEach((colItem, index) => {
                const colKey = (colItem === null || colItem === void 0 ? void 0 : colItem.dataIndex) || (colItem === null || colItem === void 0 ? void 0 : colItem.key);
                if (key === colKey) {
                    // @ts-ignore
                    columns[index] = {
                        ...(colItem || {}),
                        ...(item || {}), // 覆盖能力
                    };
                }
            });
        });
    }
    return columns;
}
function cellFormat(columns, format) {
    if (!Array.isArray(columns)) {
        return columns;
    }
    const formatMap = getCellFormat(format);
    return columns.map((item) => {
        var _a;
        // 处理表头嵌套的情况
        if (Array.isArray(item.children)) {
            item.children = cellFormat(item.children, format);
        }
        // 防止奇怪的用户传入字符串
        if (!isFunction(item.render)) {
            item.render = null;
        }
        if (!isFunction(item.cell)) {
            item.cell = null;
        }
        if (isFunction(item.render) || isFunction(item.cell)) {
            return item;
        }
        const itemFormat = (_a = item === null || item === void 0 ? void 0 : item.format) !== null && _a !== void 0 ? _a : 'text';
        // if (typeof item?.format === 'string') {
        const configFormatMatch = isPlainObject(formatMap)
            ? formatMap[itemFormat]
            : null;
        if (configFormatMatch instanceof Function) {
            return {
                ...(item !== null && item !== void 0 ? item : {}),
                ...configFormatMatch(item),
            };
        }
        if (isPlainObject(configFormatMatch)) {
            return {
                ...(item !== null && item !== void 0 ? item : {}),
                ...configFormatMatch,
            };
        }
        // }
        return item;
    });
}
function getWrapperStyle(props) {
    const ret = {};
    if (props === null || props === void 0 ? void 0 : props.fullScreenZIndex) {
        ret.zIndex = props === null || props === void 0 ? void 0 : props.fullScreenZIndex;
    }
    return ret;
}
function getFinalStyle(props) {
    const finalStyle = {
        // @ts-ignore
        ...((props === null || props === void 0 ? void 0 : props.style) || {}),
    };
    if (props === null || props === void 0 ? void 0 : props.maxHeight) {
        finalStyle.maxHeight = props === null || props === void 0 ? void 0 : props.maxHeight;
        finalStyle.overflow = 'auto';
    }
    return finalStyle;
}
function useDelegateHub(props) {
    var _a, _b, _c;
    // const [localColumns, setLocalColumns] = React.useState(props.columns);
    // const preColumns = React.useRef();
    const { sortCustom } = props;
    const [dataSource, setDataSource] = useUnitValue(props, {
        defaultValuePropName: [],
        valuePropName: 'dataSource',
    });
    const [primaryKey, setPrimaryKey] = useUnitValue(props, {
        defaultValuePropName: 'id',
        valuePropName: 'primaryKey',
    });
    const [sorts, setSorts] = useControlValue((sortCustom || {}), {
        defaultValuePropName: (props === null || props === void 0 ? void 0 : props.defaultSorts) || [],
        valuePropName: 'sorts',
        changePropName: 'onChangeSorts',
    });
    const [loading, setLoading] = useControlValue(props, {
        defaultValuePropName: false,
        valuePropName: 'loading',
        changePropName: 'loadingChange',
    });
    // const [size, setSize] = useControlValue<SizeType>(props as unknown as Obj, {
    //   defaultValuePropName: props?.defaultSize ?? 'medium',
    //   valuePropName: 'size',
    //   changePropName: 'toolbar.sizeChange',
    // });
    const { size, setSize } = useSizeChange(Object.assign(props, ((_a = props === null || props === void 0 ? void 0 : props.toolbar) === null || _a === void 0 ? void 0 : _a.sizeChange)
        ? { sizeChange: props.toolbar.sizeChange }
        : {}));
    const [fullScreen, setFullScreen] = useControlValue(props, {
        defaultValuePropName: (_b = props === null || props === void 0 ? void 0 : props.defaultIsFullScreen) !== null && _b !== void 0 ? _b : false,
        valuePropName: 'isFullScreen',
        changePropName: 'toolbar.screenChange',
    });
    const [zebra, setZebra] = useControlValue(props, {
        defaultValuePropName: (_c = props === null || props === void 0 ? void 0 : props.defaultIsZebra) !== null && _c !== void 0 ? _c : false,
        valuePropName: 'isZebra',
        changePropName: 'toolbar.zebraChange',
    });
    // const [columns, setColumns] = useControllableValue<ICnTableColumn[]>(
    //   {
    //     ...props,
    //     onChange: props?.toolbar?.columnChange,
    //   },
    //   {
    //     defaultValue: [],
    //     valuePropName:'columns'
    //   },
    // );
    // React.useEffect(() => {
    //   if (props.ColumnsPerformance) {
    //     setLocalColumns(props.columns);
    //   } else if (!isEqual(preColumns.current, props.columns)) {
    //     setLocalColumns(props.columns);
    //     preColumns.current = props.columns;
    //   }
    // }, [props.columns]);
    // const columnChange = (...res) => {
    //   setLocalColumns(...res);
    //   if (isFunction(props?.toolbar?.columnChange)) {
    //     props?.toolbar?.columnChange(...res);
    //   }
    // };
    // const [columns, setColumns] = useControlValue<ICnTableColumn[]>(
    //   {
    //     columns: localColumns,
    //     onChange: columnChange,
    //   },
    //   {
    //     defaultValuePropName: [],
    //     valuePropName: 'columns',
    //     changePropName: 'onChange',
    //   },
    // );
    // const columnSet = _get(props, 'toolbar.settings', []).filter(
    //   (item) => item?.type === 'columnSet',
    // );
    // const columnsChange = _get(columnSet, '[0].onChange', () => { });
    const [columns, setColumns] = useControlColumnsValue(props);
    return {
        dataSource,
        setDataSource,
        primaryKey,
        setPrimaryKey,
        sorts,
        setSorts,
        loading,
        setLoading,
        size,
        setSize,
        fullScreen,
        setFullScreen,
        zebra,
        setZebra,
        columns,
        setColumns,
    };
}
function useSelect(props) {
    var _a;
    const { showSelect, rowSelection, primaryKey } = props;
    const { onSelectChange } = props;
    // if (rowSelection?.useDetailValue && Array.isArray(rowSelection?.selectedRowKeys) && !isEqual(rowSelection?.selectedRowKeys, selectKeys)) {
    //   rowSelection.selectedRowKeys = rowSelection.selectedRowKeys.map((item) => {
    //     return isPlainObject(item) ? item[primaryKey] : item;
    //   });
    // }
    const [selectKeys, setSourceSelectKeys] = useControlValue(
    // @ts-ignore
    rowSelection, {
        defaultValuePropName: (_a = rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.defaultValue) !== null && _a !== void 0 ? _a : [],
        valuePropName: 'selectedRowKeys',
        changePropName: 'onChange',
    });
    // 保存每一次的selection 信息，存储给button的时候传递
    const storeSelectInfo = useRef([]);
    const hasSelect = useMemo(() => !!(showSelect || rowSelection), [showSelect, rowSelection]);
    const setSelectKeys = (...argsArr) => {
        storeSelectInfo.current = argsArr;
        if (showSelect && isFunction(onSelectChange)) {
            if (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.useDetailValue) {
                const onSelectChangeFlip = flip(onSelectChange);
                onSelectChangeFlip.apply(null, argsArr.slice(0, 2));
            }
            else {
                onSelectChange.apply(null, argsArr);
            }
        }
        // showSelect &&
        //   isFunction(onSelectChange) &&
        //   onSelectChange.apply(null, argsArr);
        // @ts-ignore
        setSourceSelectKeys(...argsArr);
    };
    const selectKeysProps = (rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.useDetailValue)
        ? selectKeys === null || selectKeys === void 0 ? void 0 : selectKeys.map((item) => (isPlainObject(item) ? item[primaryKey] : item))
        : selectKeys;
    return {
        hasSelect,
        storeSelectInfo,
        selectKeys: selectKeysProps,
        setSelectKeys,
    };
}
function useScrollRecord(fullScreen) {
    const storeScrollTop = useRef(document.documentElement.scrollTop);
    useMemo(() => {
        if (fullScreen) {
            storeScrollTop.current = document.documentElement.scrollTop;
            document.documentElement.style.position = 'fixed';
            document.documentElement.style.width = '100%';
        }
        else {
            document.documentElement.style.position = 'unset';
            document.documentElement.scrollTop = storeScrollTop.current;
        }
    }, [fullScreen]);
}
function useGetTreeConfig(props, setLoading) {
    var _a;
    const { treeConfig, remote, tree } = props;
    const [treeKeys, setTreeKeys] = useControlValue((treeConfig || {}), {
        defaultValuePropName: (_a = treeConfig === null || treeConfig === void 0 ? void 0 : treeConfig.defaultOpenKeys) !== null && _a !== void 0 ? _a : [],
        valuePropName: 'openKeys',
        changePropName: 'onChangeOpenKeys',
    });
    return {
        treeConfig: tree || treeConfig || (remote === null || remote === void 0 ? void 0 : remote.getChildrenInTree)
            ? {
                treeKeys,
                setTreeKeys,
                openKeys: treeKeys,
                onChangeOpenKeys(keys, key, action, row) {
                    var _a;
                    if (action === 'expand' &&
                        isFunction(remote === null || remote === void 0 ? void 0 : remote.getChildrenInTree)) {
                        if ((remote === null || remote === void 0 ? void 0 : remote.treeCache) && ((_a = row === null || row === void 0 ? void 0 : row.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                            // 当开启了缓存的时候，有了孩子节点就不会一直去拉取
                            setTreeKeys(keys);
                            return;
                        }
                        Promise.resolve((remote === null || remote === void 0 ? void 0 : remote.getChildrenInTree)(key, row, setLoading))
                            .then((res) => {
                            if (Array.isArray(res)) {
                                row.children = res;
                                setTreeKeys(keys);
                            }
                        })
                            .catch(() => {
                            setTreeKeys(keys);
                        });
                    }
                    else {
                        setTreeKeys(keys);
                    }
                },
                ...(treeConfig || {}),
            }
            : null,
        treeKeys,
        setTreeKeys,
    };
}
// function usePageOld(props: CnTableProps) {
//   const {
//     defaultPageSize,
//     defaultCurrentPage,
//     pageSizeList: defaultPageSizeList,
//     onPageChange,
//     pageProps = {},
//   } = props;
//   const {
//     current,
//     pageSize: pagePropsPageSize,
//     pageSizeList: pagePropsPageSizeList,
//     total: pagePropsTotal,
//   } = pageProps;
//   const pageSizeList = pagePropsPageSizeList ?? defaultPageSizeList ?? Config.pageSizeList;
//   const [currentPage, setCurrentPage] = useState(current ?? defaultCurrentPage ?? 1);
//   const [pageSize, setPageSize] = useState(
//     pagePropsPageSize ?? defaultPageSize ?? pageSizeList[0] ?? 10,
//   );
//   const [total, setTotal] = useState(pagePropsTotal ?? 0);
//   const fetchLock = useRef(false);
//   // 该锁用于防止，手动清除page带来的影响
//   const isLock = useCallback(() => {
//     return fetchLock.current;
//   }, []);
//   const doLock = useCallback(() => {
//     fetchLock.current = true;
//   }, []);
//   const unLock = useCallback(() => {
//     fetchLock.current = false;
//   }, []);
//   const currentPageChange = (val: number) => {
//     const newPageInfo = {
//       pageSizeList,
//       pageSize,
//       total,
//       currentPage: val,
//     };
//     isFunction(onPageChange) && onPageChange(newPageInfo);
//     unLock();
//     setCurrentPage(val);
//     pageChangeArmsReport();
//   };
//   const pageSizeChange = (val: number) => {
//     const newPageInfo = {
//       pageSizeList,
//       total,
//       currentPage,
//       pageSize: val,
//     };
//     isFunction(onPageChange) && onPageChange(newPageInfo);
//     unLock();
//     setCurrentPage(1);
//     setPageSize(val);
//   };
//   return {
//     currentPage,
//     setCurrentPage,
//     pageSize,
//     setPageSize,
//     total,
//     setTotal,
//     pageSizeList,
//     currentPageChange,
//     pageSizeChange,
//     fetchLock,
//     isLock,
//     doLock,
//     unLock,
//   };
// }
function useRemote(state, callback) {
    var _a;
    const { pageRenderInfo, setLoading, remote, innerParams, innerInfo } = state;
    const fetchCallRef = useLatest(fetchData);
    const isInitFetch = useRef(false);
    const lockRef = useRef(false);
    const immediateRef = useRef((_a = remote === null || remote === void 0 ? void 0 : remote.immediate) !== null && _a !== void 0 ? _a : true);
    setRemoteInstance(remote === null || remote === void 0 ? void 0 : remote.instance, fetchData);
    setInnerInfo(remote === null || remote === void 0 ? void 0 : remote.instance, innerInfo);
    // useEffect(() => {
    //   // 是否首次render禁止请求
    //   if (!immediateRef.current) {
    //     return;
    //   }
    //   // 分页更改，需要单独设置锁，因为清除分页, 不希望再次请求
    //   if (pageRenderInfo.isLock()) {
    //     pageRenderInfo.unLock();
    //     return;
    //   }
    //   isFunction(fetchCallRef.current) && fetchCallRef.current(false);
    // }, [pageRenderInfo?.pageSize, pageRenderInfo?.currentPage]);
    useEffect(() => {
        // 是否首次render禁止请求
        if (!immediateRef.current) {
            return;
        }
        // 如果是第一次请求需要保存defaultCurrentPage等参数
        if (isInitFetch.current === false) {
            safeCallFunction(fetchCallRef.current(false));
        }
        else {
            // 如果不是在第一页点查询就算参数没变也需要回归到第一页
            safeCallFunction(fetchCallRef.current(true));
        }
        isInitFetch.current = true;
        // isFunction(fetchCallRef.current) && fetchCallRef.current(true);
    }, [remote === null || remote === void 0 ? void 0 : remote.url, remote === null || remote === void 0 ? void 0 : remote.params]);
    // 该锁顺序必须在 上面两个Effect之后
    useEffect(() => {
        immediateRef.current = true;
    }, []);
    return fetchData;
    function fetchData(params) {
        var _a, _b;
        const shouldClearData = typeof params === 'object' ? params.shouldClearData : params;
        const paramsData = typeof params === 'object' ? params : {};
        // 前置判断
        if (!(remote === null || remote === void 0 ? void 0 : remote.url) && !isFunction(remote === null || remote === void 0 ? void 0 : remote.fetch)) {
            return;
        }
        if (lockRef.current) {
            lockRef.current = false;
            return;
        }
        setLoading(true);
        lockRef.current = true;
        const paramsTransform = getTransform((_a = remote === null || remote === void 0 ? void 0 : remote.paramsTransform) !== null && _a !== void 0 ? _a : Config === null || Config === void 0 ? void 0 : Config.defaultParamsTransform);
        // 获取参数
        const finalQuery = paramsTransform({
            ...shouldGetDefaultParams({
                currentPage: pageRenderInfo === null || pageRenderInfo === void 0 ? void 0 : pageRenderInfo.currentPage,
                ...((innerParams === null || innerParams === void 0 ? void 0 : innerParams.current) || {}),
            }, shouldClearData),
            pageSize: pageRenderInfo === null || pageRenderInfo === void 0 ? void 0 : pageRenderInfo.pageSize,
            ...((remote === null || remote === void 0 ? void 0 : remote.params) || {}),
            ...paramsData,
        });
        const method = (_b = remote === null || remote === void 0 ? void 0 : remote.method) !== null && _b !== void 0 ? _b : 'post';
        const doFetch = (remote === null || remote === void 0 ? void 0 : remote.fetch) instanceof Function
            ? remote === null || remote === void 0 ? void 0 : remote.fetch(finalQuery)
            : fetch({
                url: remote === null || remote === void 0 ? void 0 : remote.url,
                method,
                // data: finalQuery,
                ...((remote === null || remote === void 0 ? void 0 : remote.requestOptions) || {}),
                [(method === null || method === void 0 ? void 0 : method.toLocaleLowerCase()) === 'post' ? 'data' : 'params']: finalQuery,
            });
        (doFetch === null || doFetch === void 0 ? void 0 : doFetch.then) instanceof Function &&
            doFetch
                .then((res) => {
                function handleResponseTransform() {
                    if (isFunction(remote === null || remote === void 0 ? void 0 : remote.responseTransform)) {
                        return remote.responseTransform;
                    }
                    if (isFunction(Config.responseTransform)) {
                        return Config.responseTransform;
                    }
                    return Config.responseTransform;
                }
                const responseTransform = handleResponseTransform();
                // isFunction(remote?.responseTransform)
                //   ? remote?.responseTransform
                //   : isFunction(Config.responseTransform)
                //     ? Config.responseTransform
                //     : defaultRemoteTransform;
                // @ts-ignore 支持处理responseTransform 返回格式为Promise
                Promise.resolve(responseTransform((res === null || res === void 0 ? void 0 : res.data) || res, res)).then((finalRes) => {
                    setLoading(false);
                    lockRef.current = false;
                    callback &&
                        isFunction(callback) &&
                        callback(finalRes, shouldClearData);
                }, errorHandler);
            })
                .catch(errorHandler)
                .finally(() => {
                isFunction(remote === null || remote === void 0 ? void 0 : remote.onFinally) && (remote === null || remote === void 0 ? void 0 : remote.onFinally());
            });
    }
    function errorHandler(...args) {
        var _a, _b, _c, _d, _e;
        setLoading(false);
        lockRef.current = false;
        isFunction(remote === null || remote === void 0 ? void 0 : remote.failCallback) && (remote === null || remote === void 0 ? void 0 : remote.failCallback(...args));
        (_a = innerInfo === null || innerInfo === void 0 ? void 0 : innerInfo.setDataSource) === null || _a === void 0 ? void 0 : _a.call(innerInfo, []);
        (_c = (_b = innerInfo === null || innerInfo === void 0 ? void 0 : innerInfo.pageInfo) === null || _b === void 0 ? void 0 : _b.setTotal) === null || _c === void 0 ? void 0 : _c.call(_b, 0);
        (_e = (_d = innerInfo === null || innerInfo === void 0 ? void 0 : innerInfo.pageInfo) === null || _d === void 0 ? void 0 : _d.setPageSizeList) === null || _e === void 0 ? void 0 : _e.call(_d, []);
    }
}
function shouldGetDefaultParams(val, shouldDefault = false) {
    if (shouldDefault) {
        return {
            currentPage: 1,
        };
    }
    return val;
}
function setRemoteInstance(instance, fetchData) {
    var _a;
    // @ts-ignore
    if (isPlainObject((_a = instance === null || instance === void 0 ? void 0 : instance.remoteOperation) === null || _a === void 0 ? void 0 : _a.current)) {
        // @ts-ignore
        instance.remoteOperation.current.fetchData = fetchData;
    }
}
function setInnerInfo(instance, innerInfo) {
    var _a;
    // @ts-ignore
    if (isPlainObject((_a = instance === null || instance === void 0 ? void 0 : instance.remoteOperation) === null || _a === void 0 ? void 0 : _a.current)) {
        // @ts-ignore
        instance.remoteOperation.current.innerInfo = innerInfo;
    }
}
CNTable.useRemote = CNTable.useInstance = function () {
    const ref = useRef({});
    const fetch = useCallback((...args) => {
        var _a, _b;
        // @ts-ignore
        if (isFunction((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData)) {
            // @ts-ignore
            (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData(...args);
        }
    }, []);
    const refresh = useCallback(() => {
        fetch(true); // 发起请求，并做数据刷新
    }, []);
    const load = useCallback(() => {
        fetch(false); // 不作数据刷新
    }, []);
    const getInnerData = useCallback(() => {
        var _a;
        // @ts-ignore
        return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.innerInfo;
    }, []);
    return {
        refresh,
        load,
        remoteOperation: ref,
        getInnerData,
    };
};
CNTable.createRemoteInstance = function () {
    const ref = {
        current: {},
    };
    const fetch = (...args) => {
        var _a, _b;
        // @ts-ignore
        if (isFunction((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData)) {
            // @ts-ignore
            (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData(...args);
        }
    };
    const refresh = () => {
        fetch(true);
    };
    const load = () => {
        fetch(false);
    };
    return {
        refresh,
        load,
        remoteOperation: ref,
    };
};
CNTable.config = config;
CNTable.registerFormat = registerFormat;
CNTable.defaultProps = {
    storageKey: `CNTABLE_${window.location.href}`,
};
function expandCell(props) {
    const { cellOverflowEllipsis, cellProps, cellCopyEnable } = props || {};
    if (cellOverflowEllipsis || cellProps) {
        return (rowIndex, colIndex, key, rowData) => {
            const sourceCellProps = isFunction(cellProps)
                ? // @ts-ignore
                    cellProps(rowIndex, colIndex, key, rowData)
                : {};
            const tipContent = isFunction(cellOverflowEllipsis)
                ? cellOverflowEllipsis(rowIndex, colIndex, key, rowData)
                : // @ts-ignore
                    rowData === null || rowData === void 0 ? void 0 : rowData[key];
            const cellOverflowEllipsisProps = cellOverflowEllipsis
                ? {
                    onMouseEnter(event) {
                        var _a, _b;
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseEnter) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseEnter(event));
                        // @ts-ignore
                        const { width } = isFunction((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect)
                            ? // @ts-ignore
                                (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()
                            : {};
                        tipContent &&
                            DropDown.show(event, React.createElement("div", null, tipContent), {
                                hasMask: false,
                                offset: [0, (width !== null && width !== void 0 ? width : 0) / 2],
                                zIndex: 2000,
                                transform({ isTopHalf = true }) {
                                    return `translate(-50%, ${isTopHalf ? 0 : '-100'}%`;
                                },
                            });
                    },
                    onMouseLeave(event) {
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseLeave) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseLeave(event));
                        DropDown.hide();
                    },
                    className: 'cn-table-cell-ellipsis',
                }
                : {};
            const copyProps = cellCopyEnable
                ? {
                    onDoubleClick(event) {
                        var _a;
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onDoubleClick) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onDoubleClick(event));
                        // @ts-ignore
                        copyText((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.innerText, undefined, undefined);
                    },
                }
                : {};
            return Object.assign({}, cellOverflowEllipsisProps, sourceCellProps, copyProps);
        };
    }
}
export default CNTable;
