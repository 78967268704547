export const mapStatus = (props, field) => {
    const takeStatus = () => {
        var _a, _b;
        if (!field)
            return;
        if (field.loading || field.validating)
            return 'loading';
        if (field.invalid)
            return 'error';
        if ((_a = field.warnings) === null || _a === void 0 ? void 0 : _a.length)
            return 'warning';
        return (_b = field.decoratorProps) === null || _b === void 0 ? void 0 : _b.feedbackStatus;
    };
    const takeState = (state) => {
        if (state === 'validating' || state === 'pending')
            return 'loading';
        return state;
    };
    return {
        ...props,
        state: takeState(props.state) || takeStatus(),
    };
};
