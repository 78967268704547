import React, { forwardRef, useRef, useImperativeHandle, useEffect, } from 'react';
import { Field as FusionField } from '@/components/fusion';
import { componentName, componentItemName } from './const';
import { withI18n } from 'panda-i18n';
import isNil from 'lodash/isNil';
import Filter from './filter';
import { CnFilterItem } from './filter-item';
import FilterContext from './filter-context';
import isEqual from 'lodash/isEqual';
import locale from '@locale';
import { useCnRequest } from '@/components/cn-utils';
import { getCache, removeCache } from './cache';
const CnFilter = withI18n(forwardRef((props, ref) => {
    const filterRef = useRef();
    const filterItemCollectionRef = useRef({
        update: () => undefined,
        itemCollection: {},
        filterRef,
    });
    const { field, value, defaultValue, defaultValues, requestConfig, ...rest } = props;
    const { storageKey, cacheSearch } = props;
    React.useEffect(() => {
        if (!cacheSearch) {
            // 关闭时 清空一次session值
            removeCache(storageKey);
        }
    }, [cacheSearch]);
    const innerField = FusionField.useField({
        values: defaultValue || value || defaultValues,
    });
    useEffect(() => {
        if (!('value' in props))
            return;
        if (innerField.isOnchange) {
            // 由于 onChange 引起的 value 变化，需要渲染一次之后才能取到实际的field value(联动情形)，重新触发 onChange
            innerField.isOnchange = false;
            if (!isEqual(innerField.stateValues, innerField.getValues())) {
                innerField === null || innerField === void 0 ? void 0 : innerField.filterChange(innerField.getValues(), { field });
            }
        }
        else {
            innerField.reset();
            innerField.setValues(value);
        }
    }, [value, innerField]);
    useImperativeHandle(ref, () => ({
        getValues: () => { var _a; return (_a = filterRef === null || filterRef === void 0 ? void 0 : filterRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); },
        getField: () => { var _a; return (_a = filterRef === null || filterRef === void 0 ? void 0 : filterRef.current) === null || _a === void 0 ? void 0 : _a.getField(); },
    }));
    const isInitRemote = React.useMemo(() => {
        return !!(isNil(defaultValue) &&
            isNil(defaultValues) &&
            ((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service)));
    }, [defaultValue, defaultValues, requestConfig]);
    const readyRequestConfig = React.useMemo(() => ({
        ...requestConfig,
        ready: isInitRemote,
    }), [requestConfig, isInitRemote]);
    const { data, loading } = useCnRequest(readyRequestConfig);
    React.useEffect(() => {
        if (!isInitRemote)
            return;
        if (loading)
            return;
        innerField.setValues(data || {});
        props.onChange &&
            props.onChange(innerField.getValues(), { field: innerField });
    }, [data]);
    return (React.createElement(FilterContext.Provider, { value: filterItemCollectionRef.current },
        React.createElement(Filter, { ...rest, field: field || innerField, ref: filterRef }, props.children)));
}), { componentName: 'CnFilter', locale, forwardRef: true });
CnFilter.displayName = componentName;
CnFilterItem.displayName = componentItemName;
/**
 * @deprecated use CnFilterItem instead
 */
CnFilter.Item = CnFilterItem;
CnFilter.getCache = getCache;
CnFilter.useField = (options = {}) => {
    return FusionField.useField({
        ...options,
        values: {
            ...(options.values || {}),
            ...(getCache(options.storageKey) || {})
        }
    });
};
export { CnFilter, CnFilterItem };
