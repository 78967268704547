import * as React from 'react';
import $i18n from 'panda-i18n';
import { isVoidField } from '@formily/core';
import { connect, mapProps } from '@formily/react';
import { useFormLayout } from '@/form/cn-form-layout';
import { CnFormItem as UiCnFormItem, } from '@/components/cn-form-item';
const useFormItemLayout = (props) => {
    var _a, _b, _c;
    const layout = useFormLayout();
    return {
        ...props,
        // colon: props.colon ?? layout.colon,
        // labelWidth: props.labelWidth ?? layout.labelWidth,
        labelCol: ((_a = props.labelCol) !== null && _a !== void 0 ? _a : layout.labelCol),
        wrapperCol: ((_b = props.wrapperCol) !== null && _b !== void 0 ? _b : layout.wrapperCol),
        // size: (props.size ?? layout.size) as any,
        asterisk: props.asterisk,
        labelTextAlign: (_c = props.labelTextAlign) !== null && _c !== void 0 ? _c : layout.labelTextAlign,
    };
};
// 适配
export const CnFormItem = connect((props) => {
    const { children, ...others } = props;
    const formLayout = useFormItemLayout(others);
    function getChildren(child) {
        if (props === null || props === void 0 ? void 0 : props.fullWidth) {
            // fullWidth 撑开
            return React.cloneElement(child, {
                style: {
                    width: '100%',
                },
            });
        }
        return children;
    }
    return (React.createElement(UiCnFormItem, { ...formLayout }, getChildren(children)));
}, mapProps((props, field) => {
    if (isVoidField(field)) {
        return {
            readOnly: field.readOnly,
            label: field.title || props.label,
            asterisk: props.asterisk,
            extra: props.extra || field.description,
        };
    }
    if (!field)
        return props;
    const takeFeedbackStatus = () => {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    // 获取错误信息
    const takeMessage = () => {
        const split = (messages) => {
            return messages.reduce((buf, text) => {
                if (!text)
                    return buf;
                return buf.concat(text);
            }, []);
        };
        if (field.validating)
            return;
        if (props.feedbackText)
            return props.feedbackText;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                const requiredErrorsIndex = field.selfErrors.indexOf($i18n.get({
                    id: 'ThisFieldIsRequired',
                    dm: '该字段是必填字段',
                    ns: 'CnForm',
                }));
                if (requiredErrorsIndex > -1) {
                    field.selfErrors.splice(requiredErrorsIndex, 1, props.requiredMessage);
                }
            }
            return split(field.selfErrors);
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    // 获取是否有 required mark
    const takeAsterisk = () => {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return {
        title: props.label || field.title,
        validateState: takeFeedbackStatus(),
        errors: takeMessage(),
        asterisk: takeAsterisk(),
        extra: props.extra || field.description,
        readOnly: field.readOnly,
    };
}));
