/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo, useState, useRef, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
// import moduleName from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import debounce from 'lodash/debounce';
import { getValueByPath } from './util';
import { safeCallFunction } from '@/components/cn-utils';
import Config from './config';
import { useControllableValue, useAsyncEffect } from 'ahooks';
export function useSecond(callback, deps) {
    const hasBeSecond = useRef(false);
    useMemo(() => {
        if (hasBeSecond.current) {
            callback instanceof Function && callback();
        }
        else {
            hasBeSecond.current = true;
        }
    }, [...deps]);
}
export function useUnitValue(props, opts) {
    var _a;
    const { defaultValuePropName = [], valuePropName = 'value' } = opts;
    const [data, setData] = useState((_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValuePropName);
    const setHandler = useCallback((...args) => {
        !(valuePropName in (props || {})) && setData(...args);
    }, [props, valuePropName]);
    useSecond(() => {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
export function useControlValue(props, opts) {
    var _a;
    const { defaultValuePropName, valuePropName = 'value', changePropName = 'onChange', } = opts;
    const [data, setData] = useState((_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValuePropName);
    const setHandler = useCallback((...args) => {
        const finalChange = getValueByPath(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            // 防止render期间导致 error引发白屏
            try {
                finalChange(...args);
            }
            catch (error) {
                console.error(error);
            }
        }
        !(valuePropName in (props || {})) && setData(...args);
    }, [props, valuePropName, changePropName]);
    useSecond(() => {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
export const useUpdate = () => {
    const [, setState] = useState({});
    return useCallback(() => setState({}), []);
};
export const usePage = (props) => {
    var _a;
    const pageInfo = React.useRef({});
    const pageHasChange = React.useRef(false);
    const { defaultPageSize, defaultCurrentPage, onPageChange, pageProps = {}, } = props;
    const dataSourceLength = React.useMemo(() => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.dataSource) === null || _a === void 0 ? void 0 : _a.length; }, [props === null || props === void 0 ? void 0 : props.dataSource]);
    const pageSizeListValue = React.useMemo(() => {
        var _a;
        return (_a = pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSizeList) !== null && _a !== void 0 ? _a : props === null || props === void 0 ? void 0 : props.pageSizeList;
    }, [pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSizeList, props === null || props === void 0 ? void 0 : props.pageSizeList]);
    // const [total, setTotal] = useState(pageProps?.total ?? dataSourceLength ?? 0);
    const [total, setTotal] = useControllableValue({
        defaultValue: dataSourceLength !== null && dataSourceLength !== void 0 ? dataSourceLength : 0,
        ...(!isNil(pageProps === null || pageProps === void 0 ? void 0 : pageProps.total) ? { value: pageProps === null || pageProps === void 0 ? void 0 : pageProps.total } : {}),
        // value: pageProps?.total ?? dataSourceLength,
    });
    const [currentPage, setCurrent] = useControllableValue({
        defaultValue: defaultCurrentPage !== null && defaultCurrentPage !== void 0 ? defaultCurrentPage : 1,
        ...(!isNil(pageProps === null || pageProps === void 0 ? void 0 : pageProps.current) ? { value: pageProps === null || pageProps === void 0 ? void 0 : pageProps.current } : {}),
        onChange: (currentPage, type) => handlePageChange({ currentPage, type }),
    });
    const [pageSize, setPageSize] = useControllableValue({
        defaultValue: (_a = defaultPageSize !== null && defaultPageSize !== void 0 ? defaultPageSize : pageSizeListValue === null || pageSizeListValue === void 0 ? void 0 : pageSizeListValue[0]) !== null && _a !== void 0 ? _a : Config.defaultPageSize,
        ...(!isNil(pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSize) ? { value: pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSize } : {}),
        onChange: (pageSize) => handlePageChange({ pageSize }),
    });
    const [pageSizeList, setPageSizeList] = useControllableValue({
        defaultValue: Config.pageSizeList,
        ...(!isNil(pageSizeListValue) ? { value: pageSizeListValue } : {}),
        onChange: (pageSizeList) => handlePageChange({ pageSizeList }),
    });
    function handlePageChange(params) {
        if (params.type === 'linkage')
            return;
        // 如果pagesize发生了变化把当前页重置成第一页
        if ('pageSize' in params) {
            setCurrent(1, 'linkage');
            Object.assign(params, { currentPage: 1 });
        }
        const resultPageInfo = {
            pageSizeList,
            pageSize,
            total,
            currentPage,
            ...params,
        };
        if (!isEqual(pageInfo.current, resultPageInfo)) {
            pageHasChange.current = true;
            safeCallFunction(onPageChange, resultPageInfo);
        }
        pageInfo.current = resultPageInfo;
    }
    return {
        total,
        setTotal,
        pageSize,
        setPageSize,
        currentPage,
        setCurrent,
        pageSizeList,
        setPageSizeList,
        pageHasChange,
    };
    // const {
    //   defaultPageSize,
    //   defaultCurrentPage,
    //   pageSizeList: defaultPageSizeList,
    //   onPageChange,
    //   pageProps = {},
    // } = props;
    // const handlePageChange = () => {};
    // const [pageSizeList,]
};
export function useControlColumnsValue(props) {
    const [localColumns, setLocalColumns] = React.useState([]);
    const remoteColumnsRef = React.useRef([]);
    const localColumnsRef = React.useRef(props.columns);
    const preColumns = React.useRef();
    const storageKey = React.useMemo(() => {
        var _a;
        return (_a = safeCallFunction(props.storageKey)) !== null && _a !== void 0 ? _a : props.storageKey;
    }, [props.storageKey]);
    React.useEffect(() => {
        if (!Array.isArray(props.columns)) {
            return;
        }
        const columns = props.columns.map((item) => {
            item.copyTitle = isString(item.title) ? item.title : item.name;
            return item;
        });
        // 如果是高性能模式 columns引用变化就认为是columns发生变化
        if (props.ColumnsPerformance) {
            setColumns(columns);
            // 如果不是性能模式则深比较columns是否有变化
        }
        else if (!isEqual(preColumns.current, columns)) {
            // setLocalColumns(props.columns);
            setColumns(columns);
            preColumns.current = columns;
        }
    }, [props.columns, props.ColumnsPerformance]);
    useAsyncEffect(async () => {
        try {
            if (!isFunction(props.onGetStorage))
                return;
            const result = await props.onGetStorage();
            if (!Array.isArray(result.columns))
                return;
            remoteColumnsRef.current = result.columns;
            columnChange(localColumnsRef.current);
        }
        catch (error) { }
    }, []);
    const columnChange = (columns, type) => {
        var _a, _b, _c;
        const columnSet = (_b = (_a = _get(props, 'toolbar.settings', [])) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, (item) => (item === null || item === void 0 ? void 0 : item.type) === 'columnSet');
        const fullColumns = mergeLocalColumns(columns, type, storageKey);
        setLocalColumns(fullColumns);
        localColumnsRef.current = fullColumns;
        safeCallFunction((_c = props === null || props === void 0 ? void 0 : props.toolbar) === null || _c === void 0 ? void 0 : _c.columnChange, fullColumns, type);
        safeCallFunction(columnSet === null || columnSet === void 0 ? void 0 : columnSet.onChange, fullColumns, type);
    };
    function mergeLocalColumns(columns = [], type, storageKey) {
        function getLocalColumns() {
            if (isFunction(props.onGetStorage)) {
                return remoteColumnsRef.current;
            }
            else if (storageKey && !isFunction(props.onGetStorage)) {
                const localColumnStr = localStorage.getItem(`COLUMNS_${storageKey}`);
                return JSON.parse(localColumnStr);
            }
        }
        if (type === 'user') {
            if (storageKey && !isFunction(props.onSetStorage)) {
                const settingColumns = columns.map((item) => ({
                    dataIndex: item.dataIndex,
                    code: item.code,
                    lock: item.lock,
                    align: item.align,
                    hidden: item.hidden,
                    width: item.width,
                }));
                localStorage.setItem(`COLUMNS_${storageKey}`, JSON.stringify(settingColumns));
            }
            safeCallFunction(props.onSetStorage, { columns });
            remoteColumnsRef.current = columns;
            return columns;
        }
        else {
            const localColumns = getLocalColumns();
            if (!Array.isArray(localColumns))
                return columns;
            // 用本地的顺序合并远端columns(本地存储顺序也是列设置)
            // 因为要用本地列和远端列进行匹配,所以这里浅拷贝远端列逐一匹配删除,如果远端列有剩余就是有新增列
            const copyColumns = cloneDeep(columns);
            const mergeColumns = localColumns.reduce((mergeColumns, localItem) => {
                const columnsIndex = copyColumns.findIndex((item) => {
                    if (!isNil(item === null || item === void 0 ? void 0 : item.dataIndex)) {
                        return (localItem === null || localItem === void 0 ? void 0 : localItem.dataIndex) === item.dataIndex;
                    }
                    if (!isNil(item === null || item === void 0 ? void 0 : item.code)) {
                        return (localItem === null || localItem === void 0 ? void 0 : localItem.code) === item.code;
                    }
                    return false;
                });
                if (columnsIndex !== -1) {
                    const mergeColumnsItem = Object.assign(copyColumns[columnsIndex], pick(localItem, [
                        'dataIndex',
                        'code',
                        'lock',
                        'align',
                        'hidden',
                        'width',
                    ]));
                    mergeColumns.push(mergeColumnsItem);
                    copyColumns.splice(columnsIndex, 1);
                }
                return mergeColumns;
            }, []);
            // 如果远端还有剩余列则为新增列放到最后
            return mergeColumns.concat(copyColumns);
        }
    }
    const [columns, setColumns] = useControlValue({
        columns: localColumns,
        onChange: columnChange,
    }, {
        defaultValuePropName: [],
        valuePropName: 'columns',
        changePropName: 'onChange',
    });
    return [columns, setColumns];
}
export function useColumnsResize(props, columns, setColumns) {
    const { columnResize: columnResizeProps } = props;
    const columnResize = {};
    const onChange = React.useCallback(debounce((info, dataIndex, width) => {
        const columnsWidth = columns.map((el) => {
            if (el.dataIndex === dataIndex) {
                return {
                    ...el,
                    width,
                };
            }
            return el;
        });
        setColumns(columnsWidth, 'user');
    }, 550), [columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange, columns]);
    if (columnResizeProps === undefined)
        return columnResizeProps;
    columnResize.onChange = (...res) => {
        var _a;
        (_a = columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange) === null || _a === void 0 ? void 0 : _a.call(columnResizeProps, ...res);
        onChange(...res);
    };
    return {
        ...(typeof columnResizeProps === 'object' ? columnResizeProps : {}),
        // columnResizeProps,
        ...columnResize,
    };
}
