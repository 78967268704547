'use strict';
import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { Overlay, Balloon } from '@fusion/lib';
import { addEventListener, getClientSize, errorImage } from './utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
const initialPosition = {
    x: 0,
    y: 0,
};
// 放缩比例区间[0.1, 4]
const minScaleRate = 0.2;
const maxScaleRate = 4;
class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.addEventListener = () => {
            this.onMouseUpListener = addEventListener(window, 'mouseup', this.onMouseUp, false);
            this.onMouseMoveListener = addEventListener(window, 'mousemove', this.onMouseMove, false);
            this.onKeyDownListener =
                this.props.showSwitch &&
                    addEventListener(window, 'keydown', this.onKeyDown, false);
            try {
                // Resolve if in iframe lost event
                /* istanbul ignore next */
                if (window.top !== window.self) {
                    this.onTopMouseUpListener = addEventListener(window.top, 'mouseup', this.onMouseUp, false);
                    this.onTopMouseMoveListener = addEventListener(window.top, 'mousemove', this.onMouseMove, false);
                }
            }
            catch (error) {
                /* istanbul ignore next */
                console.warn(false, `[ImagePlayer] ${error}`);
            }
        };
        this.removeEventListener = () => {
            var _a, _b, _c;
            (_a = this.onMouseUpListener) === null || _a === void 0 ? void 0 : _a.remove();
            (_b = this.onMouseMoveListener) === null || _b === void 0 ? void 0 : _b.remove();
            this.props.showSwitch && ((_c = this.onKeyDownListener) === null || _c === void 0 ? void 0 : _c.remove());
            /* istanbul ignore next */
            if (this.onTopMouseUpListener)
                this.onTopMouseUpListener.remove();
            /* istanbul ignore next */
            if (this.onTopMouseMoveListener)
                this.onTopMouseMoveListener.remove();
        };
        this.onClose = (e) => {
            e === null || e === void 0 ? void 0 : e.stopPropagation();
            this.setState({
                chooseUrl: null,
                visible: false,
                position: initialPosition,
                scale: 1,
                rotate: 0,
            }, () => {
                if (typeof this.props.onClose === 'function') {
                    this.props.onClose();
                }
            });
        };
        this.onKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowRight': {
                    this.onSwitch('next', e);
                    return;
                }
                case 'ArrowLeft': {
                    this.onSwitch('prev', e);
                }
                default:
            }
        };
        this.onZoomIn = () => {
            this.setState((prevState) => ({
                ...prevState,
                scale: prevState.scale < maxScaleRate
                    ? prevState.scale + 0.1
                    : prevState.scale,
                position: initialPosition,
            }));
        };
        this.onZoomOut = () => {
            this.setState((prevState) => ({
                ...prevState,
                scale: prevState.scale >= minScaleRate
                    ? prevState.scale - 0.1
                    : prevState.scale,
                position: initialPosition,
            }));
        };
        this.onRotateRight = () => {
            this.setState((prevState) => ({
                ...prevState,
                rotate: prevState.rotate + 90,
            }));
        };
        this.onRotateLeft = () => {
            this.setState((prevState) => ({
                ...prevState,
                rotate: prevState.rotate - 90,
            }));
        };
        this.onMouseUp = () => {
            const { visible, isMoving, scale, rotate } = this.state;
            const isRotate = rotate % 180 !== 0;
            if (visible && isMoving) {
                const widthRaw = this.imgRef.offsetWidth * scale;
                const heightRaw = this.imgRef.offsetHeight * scale;
                const [width, height] = isRotate
                    ? [heightRaw, widthRaw]
                    : [widthRaw, heightRaw];
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { left, top } = this.imgRef.getBoundingClientRect();
                this.setState({ isMoving: false });
                const { width: clientWidth, height: clientHeight } = getClientSize();
                // 水平方向全部拖出边界，则回到屏幕中心
                const overflowHoz = left < -width
                    ? -width - left
                    : left > clientWidth
                        ? left - document.documentElement.clientWidth
                        : 0;
                // 垂直方向全部拖出边界，则回到屏幕中心
                const overflowVer = top < -height
                    ? -height - top
                    : top > clientHeight
                        ? top - document.documentElement.clientHeight
                        : 0;
                if (overflowHoz || overflowVer) {
                    this.setState({ position: initialPosition });
                }
            }
        };
        this.onMouseDown = (event) => {
            const { position } = this.state;
            // Only allow main button
            if (event.button !== 0)
                return;
            event.preventDefault();
            // Without this mask close will abnormal
            event.stopPropagation();
            this.originPositionRef.deltaX = event.pageX - position.x;
            this.originPositionRef.deltaY = event.pageY - position.y;
            this.originPositionRef.originX = position.x;
            this.originPositionRef.originY = position.y;
            this.setState({ isMoving: true });
        };
        this.onMouseMove = (event) => {
            const { visible, isMoving } = this.state;
            if (visible && isMoving) {
                this.setState({
                    position: {
                        x: event.pageX - this.originPositionRef.deltaX,
                        y: event.pageY - this.originPositionRef.deltaY,
                    },
                });
            }
        };
        this.onError = (e) => {
            const { onError, errorUrl } = this.props;
            e.target.src = errorUrl;
            onError && onError(e);
        };
        this.onSwitch = (key, e) => {
            const { onSwitch } = this.props;
            const { chooseUrl } = this.state;
            e.stopPropagation();
            this.setState({
                position: initialPosition,
                scale: 1,
                rotate: 0,
            });
            onSwitch && onSwitch(key, chooseUrl);
        };
        this.download = () => {
            const { chooseUrl } = this.state;
            const x = new XMLHttpRequest();
            x.open('GET', chooseUrl, true);
            x.responseType = 'blob';
            x.onloadend = function () {
                const url = window.URL.createObjectURL(x.response);
                const a = document.createElement('a');
                a.href = url;
                a.download = '';
                a.click();
            };
            x.send();
        };
        this.renderButton = ({ icon, onClick, tooltip = '', className = '' }, key) => {
            if (tooltip) {
                return (React.createElement(Balloon.Tooltip, { key: key, align: "b", trigger: React.createElement(CnButton, { className: classNames('cn-ui-image-viewer-image-operate-btn', className), onClick: onClick },
                        React.createElement(CnIcon, { type: icon })) }, tooltip));
            }
            return (React.createElement(CnButton, { key: key, className: classNames('cn-ui-image-viewer-image-operate-btn', className), onClick: onClick },
                React.createElement(CnIcon, { type: icon })));
        };
        this.renderOperations = (optConfig) => (React.createElement("div", { id: "operation-bar", className: 'cn-ui-image-viewer-image-player-operation-bar', onClick: (e) => e.stopPropagation() }, optConfig.map((item, index) => this.renderButton(item, index))));
        this.state = {
            chooseUrl: props.chooseUrl || '',
            visible: props.visible || false,
            rotate: 0,
            isMoving: false,
            scale: 1,
            position: initialPosition,
        };
        this.originPositionRef = {
            originX: 0,
            originY: 0,
            deltaX: 0,
            deltaY: 0,
        };
        this.imgRef = React.createRef();
    }
    componentDidMount() {
        this.addEventListener();
    }
    componentWillUnmount() {
        this.removeEventListener();
    }
    componentWillReceiveProps(nextProps, prevState) {
        this.setState({
            chooseUrl: nextProps.chooseUrl || prevState.chooseUrl,
            visible: nextProps.visible || prevState.visible,
            shouldExpandWidth: nextProps.shouldExpandWidth || prevState.shouldExpandWidth,
        });
    }
    render() {
        const { shouldPreviewExpand, shouldExpandWidth, wrapperClassName, showSwitch, hasDownload, } = this.props;
        const { chooseUrl, scale, position, rotate, visible } = this.state;
        const optConfig = [
            {
                icon: 'add',
                onClick: this.onZoomIn,
                tooltip: $i18n.get({
                    id: 'Amplification',
                    dm: '放大',
                    ns: 'CnImageViewer',
                }),
            },
            {
                icon: 'minus',
                onClick: this.onZoomOut,
                tooltip: $i18n.get({
                    id: 'Narrowing',
                    dm: '缩小',
                    ns: 'CnImageViewer',
                }),
            },
            {
                icon: 'counterclockwise',
                onClick: this.onRotateLeft,
                tooltip: $i18n.get({
                    id: 'LeftRotation',
                    dm: '左侧旋转',
                    ns: 'CnImageViewer',
                }),
            },
            {
                icon: 'refresh',
                onClick: this.onRotateRight,
                tooltip: $i18n.get({
                    id: 'RightRotation',
                    dm: '右侧旋转',
                    ns: 'CnImageViewer',
                }),
            },
        ];
        if (hasDownload) {
            optConfig.push({
                icon: 'download',
                onClick: this.download,
                tooltip: $i18n.get({ id: 'Download', dm: '下载', ns: 'CnImageViewer' }),
            });
        }
        const closeBtn = {
            icon: 'close',
            onClick: this.onClose,
            className: 'cn-ui-image-viewer-close-preview-btn',
            tooltip: $i18n.get({ id: 'Close', dm: '关闭', ns: 'CnImageViewer' }),
        };
        const leftBtn = {
            icon: 'arrow-left',
            onClick: (e) => this.onSwitch('prev', e),
            className: 'cn-ui-image-viewer-arrow-left',
            tooltip: $i18n.get({ id: 'LastOne', dm: '上一个', ns: 'CnImageViewer' }),
        };
        const rightBtn = {
            icon: 'arrow-right',
            onClick: (e) => this.onSwitch('next', e),
            className: 'cn-ui-image-viewer-arrow-right',
            tooltip: $i18n.get({ id: 'Next', dm: '下一个', ns: 'CnImageViewer' }),
        };
        const previewStyle = shouldPreviewExpand
            ? shouldExpandWidth
                ? { width: '100%' }
                : { height: '100%' }
            : {};
        return (React.createElement(Overlay, { wrapperClassName: `cn-ui-image-viewer-image-overlay ${wrapperClassName}`, visible: visible, align: "cc cc", hasMask: true, disableScroll: true, shouldUpdatePosition: true, onRequestClose: (type) => {
                // 绕过overlay bug，当鼠标按下拖动其上元素并移入开发者工具栏范围，再抬起时会触发docClick自动弹层关闭
                if (type === 'docClick')
                    return;
                this.onClose();
            } },
            React.createElement("div", { className: 'cn-ui-image-viewer-image-player-wrapper-overlay-fix', style: { width: '100%', height: '100%' }, onClick: this.onClose },
                this.renderOperations(optConfig),
                this.renderButton(closeBtn),
                React.createElement("img", { className: 'cn-ui-image-viewer-image-player', ref: (ref) => (this.imgRef = ref), src: chooseUrl, alt: "", style: {
                        transform: `
                    scale3d(${scale}, ${scale}, 1)
                    translate3d(${position.x}px, ${position.y}px, 0)
                    rotate(${rotate}deg)`,
                        ...previewStyle,
                    }, onMouseDown: this.onMouseDown, onClick: (e) => e.stopPropagation(), onError: this.onError }),
                showSwitch && (React.createElement(React.Fragment, null,
                    this.renderButton(leftBtn),
                    this.renderButton(rightBtn))))));
    }
}
Preview.propTypes = {
    /**
     * 弹层wrapper样式名
     */
    wrapperClassName: PropTypes.string,
    /**
     * 是否打开预览
     */
    visible: PropTypes.bool,
    /**
     * 图片url或url数组
     */
    chooseUrl: PropTypes.string,
    /**
     * 点击大图预览时是否拉伸长边适配屏幕
     */
    shouldPreviewExpand: PropTypes.bool,
    /**
     * 拉伸哪个边适配屏幕
     */
    shouldExpandWidth: PropTypes.bool,
    /**
     * 加载出错的占位图
     */
    errorUrl: PropTypes.string,
    /**
     * 加载出错后回调
     * @params {Event} e
     * @returns {void}
     */
    onError: PropTypes.func,
    /**
     * 关闭预览弹层后回调
     */
    onClose: PropTypes.func,
    showSwitch: PropTypes.bool,
    onSwitch: PropTypes.func,
    hasDownload: PropTypes.bool,
};
Preview.defaultProps = {
    wrapperClassName: '',
    visible: false,
    chooseUrl: '',
    shouldPreviewExpand: false,
    shouldExpandWidth: false,
    showSwitch: false,
    errorUrl: errorImage,
    onSwitch: () => { },
    onError: () => { },
};
export default Preview;
