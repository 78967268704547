import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Button } from '@/components/cn-button';
import { Drawer } from '@/components/fusion';
import { CnCard } from '@/components/cn-card';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { PandaConfigProvider } from 'panda-i18n';
import './index.scss';
function CnDrawer(props) {
    const { children, size, footer, onOk, onCancel, okProps, cancelProps, $i18n, className, embeddable, noCard, popupContainer, ...rest } = props;
    const wrapperRef = React.useRef();
    const { children: okChildren = $i18n.get({ id: 'Confirmation', dm: '确认' }), visible: okVisible = true, ...restOkProps } = okProps || {};
    const { children: cancelChildren = $i18n.get({ id: 'Cancel', dm: '取消' }), visible: cancelVisible = true, ...restCancelProps } = cancelProps || {};
    const hasCard = useCallback(() => {
        var _a;
        if (noCard) {
            return true;
        }
        if (embeddable) {
            return true;
        }
        let types = [];
        if (Array.isArray(children) && children.length > 0) {
            types = children.map((item) => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        return types.includes('CnCard');
    }, [children, embeddable]);
    const footerDom = (React.createElement(React.Fragment, null,
        footer,
        cancelVisible && (React.createElement(Button, { className: "cn-ui-drawer-btn", onClick: onCancel, ...restCancelProps }, cancelChildren)),
        okVisible && (React.createElement(Button, { className: "cn-ui-drawer-btn", type: "primary", onClick: onOk, ...restOkProps }, okChildren))));
    const cls = classnames({
        'cn-ui-drawer': true,
        [`cn-ui-drawer-${size}`]: true,
        [className]: !!className,
    });
    const drawerContent = classnames({
        'cn-ui-drawer-content': true,
        'cn-ui-drawer-content-has-footer': footer,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: wrapperRef, style: {
                display: 'none'
            } }),
        React.createElement(Drawer, { "data-name": "CnDrawer", popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : (() => { var _a; return (((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.closest('#cn-ui-page-scroll-container')) || document.body); }), ...rest, className: cls },
            React.createElement(PandaConfigProvider, null,
                React.createElement(CnScrollBar, { className: "cn-ui-drawer-wrapper" },
                    React.createElement("div", { className: drawerContent }, hasCard() ? children : React.createElement(CnCard, null, children)),
                    footer && React.createElement("div", { className: "cn-ui-drawer-footer" }, footerDom))))));
}
CnDrawer.defaultProps = {
    size: 'small',
};
export default CnDrawer;
