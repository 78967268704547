import React from 'react';
export const defaultDateFormat = {
    time: 'HH:mm:ss',
    date: 'YYYY-MM-DD',
    month: 'YYYY-MM',
    week: 'GGGG-wo',
    year: 'YYYY',
    quarter: 'YYYY-[Q]Q',
};
export const isEmpty = (value) => {
    if (['boolean', 'number'].includes(typeof value))
        return false;
    return !value;
};
export const normalizeChildren = (value) => {
    return React.Children.toArray(value);
};
export function normalizeArray(value) {
    if (isEmpty(value))
        return [];
    const _value = Array.isArray(value) ? value : [value];
    return _value.filter((val) => !isEmpty(val));
}
const _childrenToDataSource = (dataSource, children, targetDisplayName, normalizeFn, ignoreChildren) => {
    React.Children.forEach(children, (child) => {
        var _a;
        if (!targetDisplayName ||
            normalizeDisplayName(((_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.displayName) || '') === targetDisplayName) {
            dataSource.push(normalizeFn(child));
            if (ignoreChildren)
                return;
        }
        if (child === null || child === void 0 ? void 0 : child.props.children) {
            _childrenToDataSource(dataSource, child === null || child === void 0 ? void 0 : child.props.children, targetDisplayName, normalizeFn);
        }
    });
};
export const childrenToDataSource = (children, targetDisplayName, normalizeFn, ignoreChildren) => {
    const dataSource = [];
    _childrenToDataSource(dataSource, children, normalizeDisplayName(targetDisplayName), normalizeFn, ignoreChildren);
    return dataSource;
};
const _valueToDataSourceItem = (payload, value, dataSourceNode) => {
    if (payload.count === value.length)
        return;
    if (['boolean', 'number', 'string'].includes(typeof dataSourceNode)) {
        const idx = value.indexOf(dataSourceNode);
        if (idx >= 0) {
            payload.items[idx] = {
                value: dataSourceNode,
                label: dataSourceNode,
            };
            payload.count += 1;
            return;
        }
    }
    if (!isEmpty(dataSourceNode.value)) {
        const idx = value.indexOf(dataSourceNode.value);
        if (idx >= 0) {
            payload.items[idx] = dataSourceNode;
            payload.count += 1;
        }
    }
    if (Array.isArray(dataSourceNode.children)) {
        dataSourceNode.children.forEach((childNode) => {
            _valueToDataSourceItem(payload, value, childNode);
        });
    }
};
export const valueToDataSourceItem = (value, dataSource) => {
    const normalizedValue = normalizeArray(value);
    const payload = {
        count: 0,
        items: normalizedValue.slice(),
    };
    _valueToDataSourceItem(payload, normalizedValue, {
        children: dataSource,
    });
    return payload.items;
};
export const genId = (ids) => {
    return Math.max(...(ids || []), 0) + 1;
};
export function formatValue(value, formatter) {
    if (Array.isArray(value)) {
        return value.map(formatter);
    }
    return formatter(value);
}
export const normalizeDisplayName = (displayName) => {
    return (displayName || '').replace(/Config\(/g, '').replace(/\)/g, '');
};
export const getNormalizedDisplayName = (type) => {
    return normalizeDisplayName((type === null || type === void 0 ? void 0 : type.displayName) || '');
};
export const getFieldInitName = (itemProps, childProps, idx) => {
    const name = itemProps === null || itemProps === void 0 ? void 0 : itemProps.name;
    let initName = ((childProps === null || childProps === void 0 ? void 0 : childProps['data-meta']) && (childProps === null || childProps === void 0 ? void 0 : childProps.id)) || (childProps === null || childProps === void 0 ? void 0 : childProps.initName);
    if (!initName && idx === 0 && ((childProps === null || childProps === void 0 ? void 0 : childProps.name) || name)) {
        initName = (childProps === null || childProps === void 0 ? void 0 : childProps.name) || name;
    }
    return initName;
};
const componentsWithoutOverlay = [
    'Checkbox', 'Input', 'NumberPicker', 'Radio', 'Range', 'Rating', 'Switch',
    'CnCheckbox', 'CnInput', 'CnNumberPicker', 'CnRangeNumberPicker', 'CnNumberPickerState', 'CnRadio', 'CnRange', 'CnRating', 'CnSwitch',
];
export const getItemStyleInjectProps = (componentName, filterContext, props) => {
    var _a;
    if (componentsWithoutOverlay.indexOf(componentName) >= 0) {
        return null;
    }
    // 使用 v2 的组件自己覆盖 container 设置
    const popupContainer = (e) => {
        var _a, _b;
        return (_b = (_a = e === null || e === void 0 ? void 0 : e.closest('#filter-popup-container')) !== null && _a !== void 0 ? _a : e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) !== null && _b !== void 0 ? _b : document.body;
    };
    return {
        popupContainer: (_a = props === null || props === void 0 ? void 0 : props.popupContainer) !== null && _a !== void 0 ? _a : popupContainer,
        popupProps: {
            container: popupContainer,
            ...((props === null || props === void 0 ? void 0 : props.popupProps) || {}),
        },
    };
};
