import * as React from 'react';
import { DatePicker2 as NextDatePicker2 } from '@/components/fusion';
import compact from 'lodash/compact';
import { CnReadOnly } from '@/components/cn-read-only';
import { initDayjsLocale, useValueState } from '@/components/cn-utils';
import cx from 'classnames';
import { formatValue, getValueFromDayjs } from './util';
import './index.scss';
initDayjsLocale();
const { MonthPicker, YearPicker, WeekPicker, QuarterPicker, RangePicker } = NextDatePicker2;
const renderPreview = (value, readOnlyProps, format) => {
    let showValue;
    if (Array.isArray(value)) {
        showValue = compact(value);
        showValue = showValue.map((i) => (i && i.format(format)) || '');
    }
    else {
        showValue = (value && value.format(format)) || '';
    }
    return (React.createElement(CnReadOnly, { value: showValue, valueSeparator: " - ", type: "string", ...readOnlyProps }));
};
function nextCompMap(Comp, displayName) {
    const Component = React.forwardRef((props, ref) => {
        const { readOnly, readOnlyProps, outputFormat, className, showTime, endOfDay, popupContainer, ...otherProps } = props;
        const [value, setValue, isControlled] = useValueState(props, undefined);
        const datePickerRef = React.useRef();
        React.useImperativeHandle(ref, () => datePickerRef.current, [
            datePickerRef,
        ]);
        const onChange = (newValue, strVal) => {
            if (!isControlled) {
                setValue(newValue);
            }
            let v = getValueFromDayjs(newValue, !showTime, endOfDay);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onChange && props.onChange(v, strVal);
        };
        const onOk = (newValue, strVal) => {
            if (!isControlled) {
                setValue(newValue);
            }
            let v = getValueFromDayjs(newValue, !showTime, endOfDay);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onOk && props.onOk(v, strVal);
        };
        const { disabledDate } = otherProps;
        if (disabledDate) {
            otherProps.disabledDate = (date, mode) => {
                var _a, _b, _c;
                return disabledDate(date, mode, (_c = (_b = (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.getInstance()) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.value);
            };
        }
        // showTime 时的兼容逻辑，不必写 timePanelProps format
        let timePanelProps = props.timePanelProps;
        if (showTime && props.format && typeof props.format === 'string') {
            const timeFormat = props.format.trim().split(/\s+/)[1];
            if (timeFormat) {
                timePanelProps = { ...timePanelProps } || {};
                timePanelProps.format = timeFormat;
            }
        }
        return (React.createElement(Comp, { "data-name": "CnDatePickerPro", className: cx({
                'cn-ui-date-picker-pro': true,
            }, className), ref: datePickerRef, renderPreview: (renderValue) => {
                let format = showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
                if (props.format) {
                    format = props.format;
                }
                return renderPreview(renderValue, readOnlyProps, format);
            }, isPreview: props.readOnly, showTime: showTime, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || (e === null || e === void 0 ? void 0 : e.closest('.cn-next-dialog-wrapper')) || document.body), timePanelProps: timePanelProps, ...otherProps, value: value, onChange: onChange, onOk: onOk }));
    });
    Component.displayName = displayName;
    return Component;
}
/**
 * @deprecated
 * 使用 CnDatePickerPro
 */
export const CnDatePicker2 = nextCompMap(NextDatePicker2, 'CnDatePicker2');
/**
 * @deprecated
 * 使用 CnMonthPickerPro
 */
export const CnMonthPicker2 = nextCompMap(MonthPicker, 'CnMonthPicker2');
/**
 * @deprecated
 * 使用 CnYearPickerPro
 */
export const CnYearPicker2 = nextCompMap(YearPicker, 'CnYearPicker2');
/**
 * @deprecated
 * 使用 CnWeekPickerPro
 */
export const CnWeekPicker2 = nextCompMap(WeekPicker, 'CnWeekPicker2');
/**
 * @deprecated
 * 使用 CnQuarterPickerPro
 */
export const CnQuarterPicker2 = nextCompMap(QuarterPicker, 'CnQuarterPicker2');
/**
 * @deprecated
 * 使用 CnRangeDatePickerPro
 */
export const CnRangeDatePicker2 = nextCompMap(RangePicker, 'CnRangeDatePicker2');
CnRangeDatePicker2.defaultProps = {
    endOfDay: [false, true],
};
export const CnDatePickerPro = CnDatePicker2;
export const CnMonthPickerPro = CnMonthPicker2;
export const CnYearPickerPro = CnYearPicker2;
export const CnWeekPickerPro = CnWeekPicker2;
export const CnQuarterPickerPro = CnQuarterPicker2;
export const CnRangeDatePickerPro = CnRangeDatePicker2;
