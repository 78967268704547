import $i18n, { PandaConfigProvider } from 'panda-i18n';
import React from 'react';
import { Button, Dialog } from '@/components/fusion';
import classnames from 'classnames';
import './style/index.scss';
const getFooter = (props) => {
    const { footer = React.createElement(React.Fragment, null), okProps, cancelProps, onCancel, onOk } = props;
    if (footer === false)
        return false;
    const { children: okChildren = $i18n.get({
        id: 'Confirmation',
        dm: '确认',
        ns: 'CnDialog',
    }), visible: okVisible = true, ...restOkProps } = okProps || {};
    const { children: cancelChildren = $i18n.get({
        id: 'Cancel',
        dm: '取消',
        ns: 'CnDialog',
    }), visible: cancelVisible = true, ...restCancelProps } = cancelProps || {};
    const footerDom = (React.createElement(React.Fragment, null,
        footer,
        cancelVisible && (React.createElement(Button, { className: "cn-dialog-button", onClick: onCancel, ...restCancelProps }, cancelChildren)),
        okVisible && (React.createElement(Button, { className: "cn-dialog-button", type: "primary", onClick: onOk, ...restOkProps }, okChildren))));
    return footerDom;
};
// 尺寸规范
const SIZE = {
    autoLarge: '80%',
    large: '1100px',
    medium: '660px',
    small: '380px',
};
function CnDialog(props) {
    // 拆除不需要向下传递的props参数
    const { size = 'medium', className, children, hideTitle, centered = true, $i18n, ...rest } = props;
    const cls = classnames(className, 'cn-next-dialog', 'cn-ui-dialog', {
        'cn-next-hide-title': hideTitle,
    });
    return (React.createElement(Dialog, { "data-name": "CnDialog", ...rest, v2: true, centered: centered, width: SIZE[size], className: cls, footer: getFooter(props) },
        React.createElement(PandaConfigProvider, null, children)));
}
const footerActionProps = ({ okProps, cancelProps }) => ({
    okProps: okProps || {
        children: $i18n.get({
            id: 'Confirmation',
            dm: '确认',
            ns: 'CnDialog',
        }),
    },
    cancelProps: cancelProps || {
        children: $i18n.get({
            id: 'Cancel',
            dm: '取消',
            ns: 'CnDialog',
        }),
    },
});
CnDialog.success = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.success({
        ...props,
        v2: true,
        centered,
        prefix: 'cn-next-',
        className: cls,
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.warning = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.warning({
        ...props,
        v2: true,
        centered,
        prefix: 'cn-next-',
        className: cls,
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.error = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.error({
        ...props,
        v2: true,
        centered,
        prefix: 'cn-next-',
        className: cls,
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.notice = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.notice({
        ...props,
        v2: true,
        centered,
        prefix: 'cn-next-',
        className: cls,
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.help = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.help({
        ...props,
        v2: true,
        centered,
        prefix: 'cn-next-',
        className: cls,
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.alert = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.alert({
        ...props,
        v2: true,
        centered,
        prefix: 'cn-next-',
        className: cls,
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.confirm = (props) => {
    const { size = 'small', className, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    return Dialog.confirm({
        ...props,
        v2: true,
        centered,
        className: cls,
        footerActions: ['cancel', 'ok'],
        ...footerActionProps({
            okProps: props.okProps,
            cancelProps: props.cancelProps,
        }),
    });
};
CnDialog.show = (props) => {
    const { size = 'small', className, onCancel, centered = true } = props;
    const cls = classnames(size, className, 'cn-next-quick-dialog');
    // 点击取消自动关闭弹窗功能
    const handleCancel = () => {
        instance.hide();
        onCancel && onCancel();
    };
    const instance = Dialog.show({
        ...props,
        v2: true,
        centered,
        className: cls,
        footer: getFooter({ ...props, onCancel: handleCancel }),
    });
    return instance;
};
CnDialog.Inner = Dialog.Inner;
CnDialog.defaultProps = {
    size: 'medium',
    centered: true,
    footerActions: ['cancel', 'ok'],
};
export default CnDialog;
