import { createForm } from '@formily/core';
import { CnFilterPro as Filter } from './filter';
import { getCache } from './cache';
import { withI18n } from 'panda-i18n';
import locale from '@locale';
export const CnFilterPro = withI18n(Filter, {
    componentName: 'CnFilter', locale, forwardRef: true,
});
CnFilterPro.getCache = getCache;
CnFilterPro.createForm = (options = {}) => {
    return createForm({
        ...options,
        values: {
            ...(options.values || {}),
            ...(getCache(options.storageKey) || {})
        }
    });
};
export default CnFilterPro;
export { CnFilterProItem } from './components/cn-filter-pro-item';
