import * as React from 'react';
import $i18n from 'panda-i18n';
import cx from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CnIcon } from '@/components/cn-icon';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import omit from 'lodash/omit';
import { CnMessage } from '@/components/cn-message';
import { CnReadOnlyContainer } from './cn-read-only-container';
import stringRender from './render/string-render';
import numberRender from './render/number-render';
import booleanRender from './render/boolean-render';
import enumRender from './render/enum-render';
import tableCellRender from './render/table-cell-render';
import './index.scss';
const isEmpty = (value) => {
    if (isNil(value)) {
        // null or undefined
        return true;
    }
    if (value === '') {
        return true;
    }
    if (isArray(value) && !value.length) {
        return true;
    }
    return false;
};
// 单值渲染
const readOnlyItemRender = (type, value, valueSeparator, emptyRender, dataSource) => {
    if (isEmpty(value)) {
        return emptyRender();
    }
    if (type === 'string' || (!type && isString(value))) {
        return stringRender(value);
    }
    if (type === 'number' || (!type && isNumber(value))) {
        return numberRender(value);
    }
    if (type === 'boolean' || (!type && isBoolean(value))) {
        return booleanRender(value);
    }
    if (type === 'enum') {
        return enumRender(value, dataSource);
    }
    return $i18n.get({
        id: 'UnsupportedTypes',
        dm: '不支持的类型',
        ns: 'CnReadOnly',
    });
};
export function CnReadOnly(props) {
    const { emptyRender, type, value, valueSeparator, dataSource, allowCopy, tableCellProps, ...otherProps } = props;
    if (Array.isArray(value)) {
        const notEmptyValueArr = value.filter((valueItem) => !isEmpty(valueItem));
        const renderValueArr = notEmptyValueArr.map((valueItem) => readOnlyItemRender(type, valueItem, valueSeparator, emptyRender, dataSource));
        return (React.createElement(CnReadOnlyContainer, { value: value, ...otherProps }, renderValueArr.join(valueSeparator)));
    }
    if (type === 'tableCell') {
        return (React.createElement("div", { className: cx({
                'cn-ui-read-only': true,
                'table-cell': true,
                [props.className]: !!props.className,
            }), ...omit(otherProps, ['showFolder']) }, tableCellRender(tableCellProps)));
    }
    return (React.createElement(CnReadOnlyContainer, { "data-name": "CnReadOnly", value: value, ...otherProps },
        readOnlyItemRender(type, value, valueSeparator, emptyRender, dataSource),
        allowCopy && (React.createElement(CopyToClipboard, { className: "cn-ui-read-only-copy", text: value, onCopy: () => CnMessage.success($i18n.get({
                id: 'ReplicationSuccessful',
                dm: '复制成功',
                ns: 'CnReadOnly',
            })) },
            React.createElement(CnIcon, { type: "copy", size: "medium" })))));
}
CnReadOnly.defaultProps = {
    size: 'medium',
    emptyRender: () => '- -',
    valueSeparator: ',',
    line: 3,
    showFolder: false,
    allowCopy: false,
};
