import * as React from 'react';
import cx from 'classnames';
import $i18n from 'panda-i18n';
import { ExpressionScope, FormConsumer, FormProvider, } from '@formily/react';
import { createForm, setValidateLanguage } from '@formily/core';
import { Box } from '@fusion/lib';
import { Submit } from '@/form/submit';
import { Reset } from '@/form/reset';
import { createScope } from '@/form/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnPageFooter } from '@/components/cn-page-footer';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
export const CnForm = (props) => {
    const { formProps, components, schema, formLayoutProps, onSubmit, onReset, scope, footerConfig, hasFooterSubmit, form: initForm, cnFormStepInstance, children, } = props;
    const lang = $i18n.getLang();
    const RealSchemaField = React.useMemo(() => createScope(scope), [scope]);
    const form = React.useMemo(() => {
        // 如果涉及到状态的更新，须传入form实例
        if (initForm)
            return initForm;
        return createForm(formProps);
    }, [initForm, schema]);
    React.useEffect(() => {
        setValidateLanguage(lang);
    }, [lang]);
    function createFormFooter(footerProps) {
        const { submitProps, resetProps, className, customRender, ...otherProps } = footerProps || {};
        const classes = cx('cn-ui-form-footer', className);
        if (cnFormStepInstance) {
            return (React.createElement(FormConsumer, null, () => (React.createElement(CnPageFooter, { className: classes, leftSlot: React.createElement("div", { className: "cn-ui-form-footer-header" }, cnFormStepInstance.allowBack && (React.createElement(CnButton, { size: "large", onClick: () => {
                        cnFormStepInstance.back();
                    } },
                    React.createElement(CnIcon, { type: "arrow-left" }),
                    $i18n.get({
                        id: 'PreviousStep',
                        dm: '上一步',
                        ns: 'CnForm',
                    })))), rightSlot: React.createElement(Box, { className: "cn-ui-form-footer-footer", spacing: 8, direction: "row" },
                    React.createElement(Reset, { size: "large", ...resetProps, onReset: onReset }),
                    !cnFormStepInstance.allowNext && (React.createElement(Submit, { size: "large", ...submitProps, onSubmit: onSubmit, disabled: cnFormStepInstance.allowNext })),
                    cnFormStepInstance.allowNext && (React.createElement(CnButton, { size: "large", onClick: () => {
                            cnFormStepInstance.next();
                        } },
                        React.createElement(CnIcon, { type: "arrow-right" }),
                        $i18n.get({ id: 'Next', dm: '下一步', ns: 'CnForm' })))), ...otherProps }))));
        }
        if (customRender) {
            return (React.createElement(CnPageFooter, { className: classes, rightSlot: React.createElement(FormConsumer, null, customRender), ...otherProps }));
        }
        return (React.createElement(CnPageFooter, { className: classes, rightSlot: React.createElement(Box, { className: "cn-ui-form-footer-footer", spacing: 8, direction: "row" },
                React.createElement(Reset, { size: "large", ...resetProps, onReset: onReset }),
                React.createElement(Submit, { size: "large", ...submitProps, onSubmit: onSubmit })), ...otherProps }));
    }
    return (React.createElement(FormProvider, { form: form },
        React.createElement(ExpressionScope, { value: { $$form: form } },
            React.createElement(CnFormLayout, { "data-name": "CnForm", ...formLayoutProps },
                React.createElement(RealSchemaField, { schema: schema, components: components, scope: scope }),
                children,
                (hasFooterSubmit || footerConfig) && createFormFooter(footerConfig)))));
};
CnForm.defaultProps = {
    hasFooterSubmit: false,
};
