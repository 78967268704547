import $i18n from 'panda-i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { CnDialog } from '@/components/cn-dialog';
import { unpackRequest, requestAttachData, events, friendlyTips, } from '@/components/cn-utils';
import { CnMessage } from '@/components/cn-message';
import { Button } from '@/components/fusion';
import { CnOSSUpload } from '../cn-oss-upload';
import { cssPrefix } from './constant';
import ImportButton from './import-button';
import LoopDialog from './loop-dialog';
import { LOOPING_IMPORT_JOB } from '../cn-async-jobs-pro/constant';
export const CnOSSImportView = (props) => {
    const { children, style, className, buttonProps, buttonText, autoImport, uploadMsgSlot, uploadProps: cnUploadProps, createService, hideLoop, pollingService, pollingInterval, showBgProcessBtn, onCreateSuccess, onSuccess, onError, } = props;
    const [jobId, setJobId] = useState();
    const [jobCreateLoading, setJobCreateLoading] = useState(false);
    const [ossData, setOssData] = useState();
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
    const [loopDialogVisible, setLoopDialogVisible] = useState(false);
    const onCreateSuccessRef = useRef(onCreateSuccess);
    useEffect(() => {
        onCreateSuccessRef.current = onCreateSuccess;
    }, [onCreateSuccess]);
    const createServiceRef = useRef(createService);
    useEffect(() => {
        createServiceRef.current = createService;
    }, [createService]);
    const onErrorRef = useRef(onError);
    useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);
    const openUploadDialog = useCallback(() => {
        setUploadDialogVisible(true);
    }, []);
    const _onCreateJobSuccess = useCallback((job) => {
        if (onCreateSuccessRef.current) {
            onCreateSuccessRef.current(job);
        }
        else if (hideLoop) {
            CnMessage.success($i18n.get({
                id: 'TaskCreationSuccessful',
                dm: '任务创建成功',
                ns: 'CnOssImport',
            }));
        }
    }, [hideLoop]);
    /**
     * 创建导入任务
     */
    const _onCreateJob = useCallback(async (_ossData) => {
        if (!_ossData)
            return;
        const _createService = createServiceRef.current;
        setJobCreateLoading(true);
        try {
            let result;
            if (typeof _createService === 'function') {
                result = await _createService(_ossData.key);
            }
            else {
                const config = requestAttachData(_createService, {
                    key: _ossData.key,
                });
                result = await unpackRequest(config);
            }
            if (result === null || result === void 0 ? void 0 : result.jobId) {
                _onCreateJobSuccess(result);
                events.emit(LOOPING_IMPORT_JOB);
                if (hideLoop) {
                    setUploadDialogVisible(false);
                }
                else {
                    setLoopDialogVisible(true);
                    setJobId(result.jobId);
                }
            }
            else {
                throw new Error($i18n.get({
                    id: 'TheCreatedInterfaceDoesNotReturn_287404140',
                    dm: '创建接口未返回jobId，请检查导入任务是否创建成功',
                    ns: 'CnOssImport',
                }));
            }
        }
        catch (err) {
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
            else {
                friendlyTips({
                    component: 'CnImport',
                    message: $i18n.get({
                        id: 'CreateImportTaskException',
                        dm: '创建导入任务异常',
                        ns: 'CnOssImport',
                    }),
                });
            }
        }
        setJobCreateLoading(false);
    }, [hideLoop, _onCreateJobSuccess]);
    /**
     * 创建导入任务
     */
    const onCreateJob = useCallback(() => {
        _onCreateJob(ossData);
    }, [_onCreateJob, ossData]);
    /**
     * 导入文件上传成功
     */
    const onUploadSuccess = useCallback(async (fileList, file) => {
        var _a;
        const _ossParams = file.response;
        setOssData(_ossParams);
        (_a = cnUploadProps === null || cnUploadProps === void 0 ? void 0 : cnUploadProps.onChange) === null || _a === void 0 ? void 0 : _a.call(cnUploadProps, fileList, file);
        if (autoImport)
            _onCreateJob(_ossParams);
    }, [_onCreateJob, autoImport, cnUploadProps]);
    const clearState = useCallback(() => {
        setJobId(undefined);
    }, []);
    const closeUploadDialog = useCallback(() => {
        setUploadDialogVisible(false);
        setOssData(undefined);
    }, []);
    return (React.createElement("div", { "data-name": "CnOSSImport", className: classNames(`${cssPrefix}wrapper`, className), style: style },
        React.createElement(ImportButton, { buttonProps: buttonProps, buttonText: buttonText, onClick: openUploadDialog }, children),
        React.createElement(CnDialog, { className: `${cssPrefix}dialog`, title: $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' }), visible: uploadDialogVisible, onOk: onCreateJob, okProps: { visible: false }, cancelProps: { visible: false }, onClose: closeUploadDialog, cache: false, footer: React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: closeUploadDialog }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnOssImport' })),
                autoImport ? null : (React.createElement(Button, { style: { marginLeft: 8 }, disabled: !ossData, loading: jobCreateLoading, type: "primary", onClick: onCreateJob }, $i18n.get({ id: 'Import', dm: '导入', ns: 'CnOssImport' })))) },
            React.createElement("div", { className: `${cssPrefix}content-wrapper` },
                uploadMsgSlot ? (React.createElement("div", { className: `${cssPrefix}message-slot` },
                    React.createElement(CnMessage, { type: "notice" }, uploadMsgSlot))) : null,
                React.createElement(CnOSSUpload, { className: `${cssPrefix}upload`, shape: "dragger", ...cnUploadProps, multiple: false, limit: 1, onChange: onUploadSuccess }))),
        jobId ? (React.createElement(LoopDialog, { jobId: jobId, onRender: closeUploadDialog, visible: loopDialogVisible, setVisible: setLoopDialogVisible, pollingService: pollingService, pollingInterval: pollingInterval, showBgProcessBtn: showBgProcessBtn, onSuccess: onSuccess, onError: onError, onClose: clearState })) : null));
};
CnOSSImportView.defaultProps = {
    accept: '.xls,.xlsx,.csv',
};
