import $i18n from 'panda-i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { events, unpackRequest, requestAttachData, friendlyTips, } from '@/components/cn-utils';
import { CnMessage } from '@/components/cn-message';
import { cssPrefix } from './constant';
import ExportButton from './export-button';
import { renameQuickDialog } from './rename-quick-dialog';
import LoopDialog from './loop-dialog';
import { LOOPING_EXPORT_JOB } from '../cn-async-jobs-pro/constant';
import { CnDialog } from '../cn-dialog';
export function CnExport(props) {
    const { style, className, buttonProps, buttonText, children = null, needRename = false, renameDialogProps, createService, hideLoop, pollingService, pollingInterval, showBgProcessBtn, autoDownload, onCreateSuccess, onSuccess, onError, } = props;
    const [jobId, setJobId] = useState();
    const [createJobLoading, setCreateJobLoading] = useState(false);
    const [loopDialogVisible, setLoopDialogVisible] = useState(false);
    const createServiceRef = useRef(createService);
    useEffect(() => {
        createServiceRef.current = createService;
    }, [createService]);
    const renameDialogPropsRef = useRef(renameDialogProps);
    useEffect(() => {
        renameDialogPropsRef.current = renameDialogProps;
    }, [renameDialogProps]);
    const onCreateSuccessRef = useRef(onCreateSuccess);
    useEffect(() => {
        onCreateSuccessRef.current = onCreateSuccess;
    }, [onCreateSuccess]);
    const onErrorRef = useRef(onError);
    useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);
    const openAsyncDialog = useCallback(async () => {
        if (!hideLoop)
            setLoopDialogVisible(true);
    }, [hideLoop]);
    const createJob = useCallback(async (params) => {
        const _createService = createServiceRef.current;
        if (typeof _createService === 'function')
            return _createService(params);
        const config = requestAttachData(_createService, params);
        return unpackRequest(config);
    }, []);
    const _onCreateSuccess = useCallback(async (data) => {
        const _jobId = ['number', 'string'].includes(typeof data)
            ? data
            : data.jobId;
        if (['number', 'string'].includes(typeof data)) {
            friendlyTips({
                component: 'CnExport',
                message: $i18n.get({
                    id: 'WhenCreatingTheDataStructureReturned_1770598113',
                    dm: '创建导出的任务返回的数据结构必须时对象，为了避免后期出现问题，请尽快调整',
                    ns: 'CnExport',
                }),
            });
        }
        if (!_jobId) {
            throw new Error($i18n.get({
                id: 'TheReturnedStructureOfTheCreated_1950281892',
                dm: '创建任务服务返回结构不正确，必须包含jobId',
                ns: 'CnExport',
            }));
        }
        events.emit(LOOPING_EXPORT_JOB);
        if (!hideLoop) {
            setJobId(_jobId);
            openAsyncDialog();
        }
        if (onCreateSuccessRef.current) {
            onCreateSuccessRef.current(data);
        }
        else if (hideLoop) {
            CnMessage.notice($i18n.get({
                id: 'ExportInProgress',
                dm: '导出进行中',
                ns: 'CnExport',
            }));
        }
    }, [hideLoop, openAsyncDialog]);
    const clearState = useCallback(() => {
        setJobId(undefined);
    }, []);
    const clearTask = useCallback(() => {
        setJobId(undefined);
        setLoopDialogVisible(false);
    }, []);
    const onClick = useCallback(async () => {
        clearTask();
        try {
            const _jobName = needRename
                ? await renameQuickDialog(renameDialogPropsRef.current)
                : undefined;
            setCreateJobLoading(true);
            let data = await createJob({ jobName: _jobName });
            if (data.needAudit) {
                try {
                    await new Promise((resolve, reject2) => {
                        CnDialog.confirm({
                            title: $i18n.get({
                                id: 'ExportDataApproval',
                                dm: '导出数据审批',
                                ns: 'CnExport',
                            }),
                            content: $i18n.get({
                                id: 'ExportOfThisDataRequiresApproval_1240034591',
                                dm: '导出该数据需经过审批，是否提交导出数据审批流程？',
                                ns: 'CnExport',
                            }),
                            onOk: resolve,
                            okProps: {
                                children: $i18n.get({
                                    id: '31255239613091840.CNTM',
                                    dm: '提交',
                                    ns: 'CnExport',
                                }),
                            },
                            onCancel: reject2,
                        });
                    });
                    data = await createJob({ jobName: _jobName, __needAudit: true });
                    const { auditUrl } = data;
                    if (auditUrl)
                        window.open(auditUrl);
                }
                catch { }
            }
            await _onCreateSuccess(data);
        }
        catch (err) {
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
            else {
                friendlyTips({
                    component: 'CnExport',
                    message: $i18n.get({
                        id: 'FailedToCreateExportTaskTask_123840374',
                        dm: '创建导出任务任务失败',
                        ns: 'CnExport',
                    }),
                });
            }
        }
        setCreateJobLoading(false);
    }, [clearTask, needRename, createJob, _onCreateSuccess]);
    return (React.createElement("div", { "data-name": "CnExport", className: classNames(`${cssPrefix}wrapper`, className), style: style },
        React.createElement(ExportButton, { loading: createJobLoading, onClick: onClick, buttonProps: buttonProps, buttonText: buttonText }, children),
        hideLoop ? null : (React.createElement(LoopDialog, { jobId: jobId, autoDownload: autoDownload, visible: loopDialogVisible, setVisible: setLoopDialogVisible, pollingService: pollingService, pollingInterval: pollingInterval, showBgProcessBtn: showBgProcessBtn, onSuccess: onSuccess, onError: onError, onClose: clearState }))));
}
