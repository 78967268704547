import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CnMessage } from '@/components/cn-message';
import { unpackRequest, requestAttachData } from '@/components/cn-utils';
export default function useJobPolling(props) {
    const { jobId, onSuccess, onError, pollingInterval: _pollingInterval = 5000, pollingService, } = props;
    const pollingInterval = _pollingInterval < 3000 ? 3000 : _pollingInterval;
    const [jobResult, setJobResult] = useState();
    const firstLoop = useRef(true);
    const taskId = useRef();
    const jobAbortController = useRef();
    const onErrorRef = useRef(onError);
    useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);
    const onSuccessRef = useRef(onSuccess);
    useEffect(() => {
        onSuccessRef.current = onSuccess;
    }, [onSuccess]);
    const pollingServiceRef = useRef(pollingService);
    useEffect(() => {
        pollingServiceRef.current = pollingService;
    }, [pollingService]);
    const stopTaskLoop = useCallback(() => {
        var _a;
        firstLoop.current = true;
        (_a = jobAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        if (taskId.current) {
            clearTimeout(taskId.current);
            taskId.current = null;
        }
    }, []);
    const _startTask = useCallback(async () => {
        var _a, _b;
        const _pollingService = pollingServiceRef.current;
        if (!_pollingService)
            return undefined;
        try {
            let result;
            if (typeof _pollingService === 'function') {
                result = await _pollingService(jobId);
            }
            else {
                const config = requestAttachData(_pollingService, { jobId });
                result = await unpackRequest({
                    ...config,
                    signal: (_a = jobAbortController.current) === null || _a === void 0 ? void 0 : _a.signal,
                });
            }
            if (result) {
                setJobResult(result);
                (_b = onSuccessRef.current) === null || _b === void 0 ? void 0 : _b.call(onSuccessRef, result);
                return result;
            }
        }
        catch (err) {
            if ((err === null || err === void 0 ? void 0 : err.message) === 'canceled')
                return undefined;
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
            else if (err.message) {
                CnMessage.error(err.message);
            }
        }
        return undefined;
    }, [jobId]);
    const startTaskLoop = useCallback(async () => {
        if (!jobId)
            return;
        const result = await _startTask();
        if ((result === null || result === void 0 ? void 0 : result.status) !== 'EXECUTING')
            return;
        const ts = firstLoop.current ? 2000 : pollingInterval;
        firstLoop.current = false;
        await new Promise((resolve) => setTimeout(resolve, ts));
        await startTaskLoop();
    }, [jobId, _startTask, pollingInterval]);
    useEffect(() => {
        startTaskLoop();
        return () => {
            stopTaskLoop();
        };
    }, [startTaskLoop, stopTaskLoop]);
    const result = useMemo(() => ({ jobResult, stopTaskLoop }), [jobResult, stopTaskLoop]);
    return result;
}
