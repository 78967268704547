import * as React from 'react';
import cx from 'classnames';
import { Dropdown } from '@/components/fusion';
import { CnInput, CnInputTextArea } from '@/components/cn-input';
import { CnIcon } from '@/components/cn-icon';
import { useControllableValue } from 'ahooks';
import isEqual from 'lodash/isEqual';
import './index.scss';
const formatValue = (value) => value.replace(/(,)+/g, ',').replace(/(\n)+/g, '\n');
const processInputValue = (inputVal) => {
    if (typeof inputVal !== 'string')
        return inputVal;
    return formatValue(inputVal.replace(/,/g, '\n')).replace(/\n$/, '');
};
const processTextareaValue = (textareaVal) => {
    if (typeof textareaVal !== 'string')
        return textareaVal;
    return formatValue(textareaVal.replace(/\n/g, ',')).replace(/,$/, '');
};
export const CnBatchInput = (props) => {
    const { className, onChange, value, defaultValue, inputProps, textAreaProps, } = props;
    const inputRef = React.useRef(null);
    const wrapperRef = React.useRef(null);
    const [dropDownVisible, setDropDownVisible] = React.useState(false);
    const [inputValue, setInputValue] = useControllableValue(props);
    const [textareaValue, setTextareaValue] = React.useState(processInputValue(inputValue));
    React.useEffect(() => {
        if ('value' in props) {
            return;
        }
        const newValue = processTextareaValue(textareaValue);
        if (!isEqual(newValue, inputValue)) {
            setInputValue(newValue);
        }
    }, [textareaValue]);
    React.useEffect(() => {
        const newValue = processInputValue(inputValue);
        if (!isEqual(newValue, textareaValue)) {
            setTextareaValue(newValue);
        }
    }, [inputValue]);
    return (React.createElement("div", { className: cx({
            'cn-ui-batch-input': true,
            [className]: !!className,
        }), ref: wrapperRef },
        React.createElement(Dropdown, { visible: dropDownVisible, onVisibleChange: (visible, type) => {
                if (type === 'fromTrigger') {
                    setDropDownVisible(true);
                }
                else {
                    setDropDownVisible(visible);
                }
            }, wrapperClassName: "cn-ui-batch-input-popup", triggerType: "click", target: inputRef.current, trigger: React.createElement(CnInput, { ref: inputRef, hint: React.createElement(CnIcon, { type: "icon-bill-more", className: "add-icon" }), ...inputProps, value: inputValue, onChange: setInputValue }) },
            React.createElement("div", { className: "popup-wrapper" },
                React.createElement("div", { className: "popup-title" },
                    React.createElement("span", null, "\u6279\u91CF\u6DFB\u52A0"),
                    React.createElement("span", { className: "tips" }, "\uFF08\u6BCF\u6761\u6570\u636E\u56DE\u8F66\u952E\u6362\u884C\uFF09")),
                React.createElement(CnInputTextArea, { hasBorder: false, rows: 8, ...textAreaProps, className: cx({
                        'popup-textarea': true,
                        [textAreaProps === null || textAreaProps === void 0 ? void 0 : textAreaProps.className]: !!(textAreaProps === null || textAreaProps === void 0 ? void 0 : textAreaProps.className)
                    }), value: textareaValue, onChange: setTextareaValue })))));
};
CnBatchInput.displayName = 'CnBatchInput';
