import { getCnEnv } from 'cn-base-utils';
const showRecord = {};
const env = getCnEnv();
/**
 * 开发提示
 */
export function friendlyTips(_options) {
    const options = typeof _options === 'string' ? { message: _options } : _options;
    const { message, component, once = true, showEnv = ['daily', 'pre'], type = 'warn', } = options;
    const msg = component ? `【${component}】${message}` : message;
    if (!showEnv.includes(env))
        return;
    if (once && showRecord[msg])
        return;
    showRecord[msg] = (showRecord[msg] || 0) + 1;
    try {
        // eslint-disable-next-line no-console
        console[type](msg);
        // eslint-disable-next-line no-empty
    }
    catch { }
}
