import React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { Progress } from '@/components/fusion';
import { downloadFile } from '@/components/cn-utils';
export default function JobItemRight(props) {
    const { type, data } = props;
    const { progress, status, downloadUrl } = data;
    if (status === 'EXECUTING') {
        return (React.createElement(Progress, { size: "small", style: { width: 32, height: 32 }, percent: progress, textRender: (percent) => React.createElement("span", null, percent), shape: "circle" }));
    }
    if (downloadUrl) {
        return (React.createElement(CnIcon, { onClick: () => downloadFile(downloadUrl), size: 32, type: "arrow-r-down-fill" }));
    }
    if (type === 'import') {
        if (status === 'SUCCEEDED') {
            return React.createElement(CnIcon, { size: 32, type: "complete-r" });
        }
    }
    return null;
}
