import { useI18n } from 'panda-i18n';
import React, { useState, } from 'react';
import { observer, useParentForm } from '@formily/react';
import { Select, Button, Input, Overlay, Icon, } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnBalloon } from '@/components/cn-balloon';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { CnFilterProItem } from '../cn-filter-pro-item';
import { useFilterRefsContext, useFilterPropsContext } from '../../context';
import { SaveSelectedActions, SaveSelectedActionTypes } from '../../store';
export const SaveSelector = observer(({ ...props }) => {
    const $i18n = useI18n();
    const filterContext = useFilterRefsContext();
    const { beforeSetSaveSelected, saveSelectedState: store, saveSelectedDispatch: dispatch, saveSelectSpan: colSpan, size, fullWidth, labelAlign, labelTextAlign, labelCol, wrapperCol, } = useFilterPropsContext();
    const [habitSelectVisible, setHabitSelectVisible] = useState(false);
    const form = useParentForm();
    const { selected, options, overlayVisible } = store;
    const { currentSelectedValuesRef, overlayRef } = filterContext;
    const handleRemove = (item, e) => {
        e.stopPropagation();
        dispatch({
            type: SaveSelectedActionTypes.SetOptions,
            payload: options.filter((option) => option.value !== item.value),
        });
    };
    const asyncHandleSelect = async (value) => {
        dispatch({
            type: SaveSelectedActionTypes.SetSelected,
            payload: value || undefined,
        });
        const target = value && (options || []).find((option) => option.value === value);
        if (!target || !target.data) {
            filterContext.formInstance.filterReset && filterContext.formInstance.filterReset();
            currentSelectedValuesRef.current = null;
            filterContext.formInstance.filterSearch && filterContext.formInstance.filterSearch();
            return;
        }
        let _values = target.data;
        if (beforeSetSaveSelected) {
            _values = (await beforeSetSaveSelected(_values)) || _values;
        }
        form.reset('*');
        currentSelectedValuesRef.current = _values;
        form.setValues(_values);
        filterContext.formInstance.filterChange && filterContext.formInstance.filterChange(_values);
        filterContext.formInstance.filterSearch && filterContext.formInstance.filterSearch();
    };
    const handleSelect = (value) => {
        asyncHandleSelect(parseInt(value, 10));
    };
    const renderOption = (item) => {
        const removeTriggerRef = React.createRef();
        const handleRemoveClose = () => {
            var _a;
            ((_a = removeTriggerRef.current) === null || _a === void 0 ? void 0 : _a.click) && removeTriggerRef.current.click();
        };
        return (React.createElement("span", { className: "cn-ui-filter-save-option" },
            React.createElement("span", { className: "option-label" }, item.label),
            React.createElement("span", { onClick: (e) => e && e.stopPropagation() },
                React.createElement(CnBalloon, { v2: true, align: "tl", autoFocus: true, trigger: React.createElement("span", { ref: removeTriggerRef },
                        React.createElement(Button, { text: true, size: "large" },
                            React.createElement(CnIcon, { type: "close", className: "option-delete-icon" }))), closable: false, triggerType: "click" },
                    React.createElement("div", { className: "cn-ui-filter-save-text" }, $i18n.get({
                        id: 'ThisFilterCombinationWillBeLost_972042728',
                        dm: '确定要删除当前查询习惯吗？',
                    })),
                    React.createElement("div", { className: "cn-ui-filter-save-btns" },
                        React.createElement(Button, { type: "primary", size: "small", onClick: (e) => handleRemove(item, e) }, $i18n.get({ id: 'ok', dm: '确认' })),
                        React.createElement(Button, { size: "small", onClick: handleRemoveClose }, $i18n.get({ id: 'cancel', dm: '取消' })))))));
    };
    return (React.createElement(CnFilterProItem, { title: $i18n.get({ id: 'myCriteria', dm: '查询习惯' }), key: "cnfilter-saved-selector", size: size, 
        // colSpan={colSpan}
        fullWidth: fullWidth, labelAlign: labelAlign, labelTextAlign: labelTextAlign, labelCol: labelAlign === 'left' ? (labelCol !== null && labelCol !== void 0 ? labelCol : { fixedSpan: 5 }) : undefined, wrapperCol: wrapperCol, _shouldInsertProps: false },
        React.createElement(Select, { ref: (ref) => {
                overlayRef.current = ref;
            }, className: "select cn-ui-filter-habit", value: selected, dataSource: options, onChange: (value) => {
                handleSelect(value);
            }, itemRender: (item) => renderOption(item), showSearch: true, hasClear: true, visible: habitSelectVisible, onVisibleChange: setHabitSelectVisible, onFocus: () => {
                dispatch({
                    type: SaveSelectedActionTypes.SetOverlayVisible,
                    payload: false,
                });
            }, addonAfter: React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(Button, { size: size, className: "save", onMouseDown: (e) => {
                        e.stopPropagation();
                        dispatch({
                            type: SaveSelectedActionTypes.SetOverlayVisible,
                            payload: !overlayVisible,
                        });
                        setHabitSelectVisible(false);
                        sendLog({
                            id: 'cn-ui.cn-filter.clickSaveSelected',
                        });
                    }, onClick: (e) => {
                        e.stopPropagation();
                    } },
                    React.createElement(CnIcon, { type: "icon-favorites", size: size })) }, $i18n.get({
                id: 'PreservationHabits',
                dm: '保存习惯',
                ns: 'CnFilter',
            })) })));
});
export const InputSaveOverlay = ({ enableSaveSelected, values, beforeGetSaveSelected, store, dispatch, }) => {
    var _a;
    const $i18n = useI18n();
    const [name, setName] = useState('');
    const filterContext = useFilterRefsContext();
    const { overlayVisible, options, selected } = store;
    const { overlayRef } = filterContext;
    const handleClose = () => {
        dispatch({
            type: SaveSelectedActionTypes.SetOverlayVisible,
            payload: false,
        });
    };
    const asyncHandleSave = async () => {
        let _values = values;
        if (beforeGetSaveSelected) {
            _values = (await beforeGetSaveSelected(_values)) || _values;
        }
        dispatch(SaveSelectedActions.saveSetOptions(store, {
            data: _values,
            name,
        }));
        setName('');
        handleClose();
    };
    const handleSave = () => {
        sendLog({
            id: 'cn-ui.cn-filter.saveSelectedOk',
        });
        asyncHandleSave();
    };
    const renderSaveValidate = () => {
        const sameItem = options.find((i) => i.label === name);
        if (sameItem) {
            return (React.createElement("div", { className: "cn-ui-filter-save-panel-msg" },
                React.createElement(Icon, { className: "cn-next-icon-warning", style: { color: '#FFA64E', marginRight: 5 } }),
                $i18n.get({
                    id: 'TheNameIsDuplicatedAndWill_401244060',
                    dm: '名称重复，保存后将覆盖',
                    ns: 'CnFilter',
                })));
        }
        return '';
    };
    if (!enableSaveSelected)
        return null;
    return (React.createElement(Overlay.Popup, { visible: overlayVisible, target: overlayRef.current, safeNode: overlayRef.current, onRequestClose: () => {
            dispatch({
                type: SaveSelectedActionTypes.SetOverlayVisible,
                payload: false,
            });
        }, align: "tc bc", container: e => e === null || e === void 0 ? void 0 : e.closest('#filter-popup-container') },
        React.createElement("div", { className: "cn-ui-filter-save-panel", style: { width: ((_a = overlayRef === null || overlayRef === void 0 ? void 0 : overlayRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 200 } },
            React.createElement(Input, { value: name, onChange: setName }),
            renderSaveValidate(),
            React.createElement("div", { className: "cn-ui-filter-save-panel-btns" },
                React.createElement(Button, { onClick: () => {
                        dispatch({
                            type: SaveSelectedActionTypes.SetOverlayVisible,
                            payload: false,
                        });
                        sendLog({
                            id: 'cn-ui.cn-filter.saveSelectedCancel',
                        });
                    } }, $i18n.get({ id: 'cancel', dm: '取消', ns: 'CnFilter' })),
                React.createElement(Button, { type: "primary", onClick: handleSave }, $i18n.get({ id: 'Save', dm: '保存', ns: 'CnFilter' }))))));
};
