import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { requestAttachData, unpackRequest } from '@/components/cn-utils';
import useInitPolling from './use-init-polling';
const getProgress = (list) => {
    const countProgress = list.reduce((total, item) => total + (item.progress || 0), 0);
    return Math.round(countProgress / list.length);
};
const getJobStatus = (list) => {
    return list.some((item) => item.status === 'EXECUTING')
        ? 'running'
        : 'finished';
};
/**
 * 计算本次处理结束的任务
 * 本地轮询结果和上次轮询结果进行对比
 */
const getProcessedJobs = (_prevList, _list) => {
    const list = _list.filter((item) => item.status !== 'EXECUTING');
    const _prevIdList = _prevList.map((item) => item.jobId);
    const prevIdList = _prevList
        .filter((item) => item.status === 'EXECUTING')
        .map((item) => item.jobId);
    return list.filter(({ jobId, status }) => {
        if (!_prevIdList.length)
            return false;
        if (_prevIdList.length && !_prevIdList.includes(jobId))
            return true;
        return status !== 'EXECUTING' && prevIdList.includes(jobId);
    });
};
export default function useJobsPolling(props) {
    const { onSuccess, onError, pollingInterval: _pollingInterval = 5000, pollingService, } = props;
    const pollingInterval = _pollingInterval < 3000 ? 3000 : _pollingInterval;
    const [jobResult, setJobResult] = useState([]);
    const jobAbortController = useRef();
    const taskId = useRef();
    const prevJobResult = useRef([]);
    const onErrorRef = useRef(onError);
    useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);
    const onSuccessRef = useRef(onSuccess);
    useEffect(() => {
        onSuccessRef.current = onSuccess;
    }, [onSuccess]);
    const pollingServiceRef = useRef(pollingService);
    useEffect(() => {
        pollingServiceRef.current = pollingService;
    }, [pollingService]);
    const stopTaskLoop = useCallback(() => {
        var _a;
        (_a = jobAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        if (taskId.current) {
            clearTimeout(taskId.current);
            taskId.current = null;
            prevJobResult.current = [];
        }
    }, []);
    const _startTask = useCallback(async () => {
        var _a, _b;
        jobAbortController.current = new AbortController();
        const _pollingService = pollingServiceRef.current;
        if (!_pollingService)
            return undefined;
        try {
            let res;
            if (typeof _pollingService === 'function') {
                res = await _pollingService();
            }
            else {
                const config = requestAttachData(_pollingService, {
                    currentPage: 1,
                    pageSize: 50,
                });
                res = await unpackRequest({
                    ...config,
                    signal: (_a = jobAbortController.current) === null || _a === void 0 ? void 0 : _a.signal,
                });
            }
            const result = res.tableData || [];
            setJobResult((prev) => {
                prevJobResult.current = prev;
                return result;
            });
            (_b = onSuccessRef.current) === null || _b === void 0 ? void 0 : _b.call(onSuccessRef, res);
            return result;
        }
        catch (err) {
            if ((err === null || err === void 0 ? void 0 : err.message) === 'canceled')
                return [];
            setJobResult([]);
            if (onErrorRef.current) {
                onErrorRef.current(err);
            }
        }
        return [];
    }, []);
    const startTaskLoop = useCallback(async () => {
        stopTaskLoop();
        const result = await _startTask();
        if (getJobStatus(result) === 'running') {
            taskId.current = setTimeout(startTaskLoop, pollingInterval);
        }
    }, [_startTask, stopTaskLoop, pollingInterval]);
    useInitPolling({ stopTaskLoop, startTaskLoop });
    const result = useMemo(() => {
        const processedJobs = getProcessedJobs(prevJobResult.current, jobResult);
        return {
            startTaskLoop,
            stopTaskLoop,
            jobResult,
            progress: getProgress(jobResult),
            hasUnreadJob: Boolean(processedJobs.length),
            loading: jobResult.some((job) => job.status === 'EXECUTING'),
            processedJobs,
        };
    }, [jobResult, startTaskLoop, stopTaskLoop]);
    return result;
}
