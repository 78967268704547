import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from 'cn-base-utils';
import './cn-status-tag.scss';
export const CnStatusTag = (props) => {
    const { type, status, text, children } = props;
    const classes = cx({
        'cn-ui-status-tag': true,
        [`cn-ui-status-tag-${type}`]: !!type,
        [`cn-ui-status-tag-status-${status}`]: !!status,
    });
    return withNativeProps(props, React.createElement("div", { className: classes }, children || text));
};
CnStatusTag.displayName = 'CnStatusTag';
CnStatusTag.defaultProps = {
    type: 'normal',
};
