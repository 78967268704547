/* panda-disable */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-extraneous-dependencies */
import { fromEvent } from 'rxjs';
import * as op from 'rxjs/operators';
import * as React from 'react';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import useUpdate from 'ahooks/lib/useUpdate';
import styled from 'styled-components';
import { calcColumnWidths } from '../../view/autoWidth';
import { operateCode } from './operateColumnPipeline';
import { globalAsyncDragCol } from '../global';
import { isDef, isFunction, isPlainObject, safeRenderHeader, isLeafNode, makeRecursiveMapper, collectNodes, safeCellPaddingWidth, } from '../utils';
import { useWillUpdate } from '../hooks';
import { columnResizeChangeArmsReport } from '@/components/cn-table/view/arms';
import DragLine from '../dep/DragLine';
function clamp(min, x, max) {
    return Math.max(min, Math.min(max, x));
}
function findTarget(el, match) {
    let current = el;
    while (current) {
        if (match(current)) {
            break;
        }
        current = current.parentElement;
    }
    return current;
}
const ResizeHandle = styled.span `
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  cursor: col-resize;
  z-index: 1001;
`;
// 只用来当枚举值判断实际未在视图中使用，故无需提取多语言
// 我觉得枚举就是干这种事的，这里将能力的定义分为四个层次
export var ColAbility;
(function (ColAbility) {
    ColAbility["none"] = "\u6CA1\u6709\u4EFB\u4F55\u80FD\u529B";
    ColAbility["justWidth"] = "\u53EA\u6709\u5BBD\u5EA6\u80FD\u529B";
    ColAbility["widthAndResize"] = "\u5BBD\u5EA6\u80FD\u529B\u548C\u62D6\u62FD\u80FD\u529B";
    ColAbility["widthAndAsyncResize"] = "\u5BBD\u5EA6\u80FD\u529B\u548C\u5F02\u6B65\u62D6\u62FD\u80FD\u529B";
})(ColAbility || (ColAbility = {}));
export default function columnsResizePipeline(resizeOption) {
    const { columnResize, widthsRef, dragLineRef, ability, update, size } = resizeOption;
    // 允许拖拽
    const allowResize = ability === ColAbility.widthAndAsyncResize ||
        ability === ColAbility.widthAndResize;
    // 是否可异步拖拽
    const allowColAsyncDrag = ability === ColAbility.widthAndAsyncResize;
    const { minWidth = 4, maxWidth = Infinity, disableUserSelectWhenResizing = true, } = columnResize || {};
    const asyncDragColHandler = (val) => {
        var _a, _b;
        isFunction((_a = dragLineRef === null || dragLineRef === void 0 ? void 0 : dragLineRef.current) === null || _a === void 0 ? void 0 : _a.setOffset) &&
            ((_b = dragLineRef === null || dragLineRef === void 0 ? void 0 : dragLineRef.current) === null || _b === void 0 ? void 0 : _b.setOffset(val));
    };
    const onChange = (...args) => {
        console.log('拖拽触发改变');
        if (!(columnResize === null || columnResize === void 0 ? void 0 : columnResize.widths)) {
            widthsRef.current = args[0];
            update();
        }
        if ((columnResize === null || columnResize === void 0 ? void 0 : columnResize.onChange) instanceof Function) {
            columnResize.onChange(...args);
        }
        columnResizeChangeArmsReport();
    };
    const widths = widthsRef.current;
    const startResize = (nowColumn, startIndex, allLeafColumns, e) => {
        var _a;
        const startX = e.clientX;
        let asyncRecord = null;
        const nowColCode = (_a = nowColumn === null || nowColumn === void 0 ? void 0 : nowColumn.code) !== null && _a !== void 0 ? _a : startIndex;
        const nowWidths = getInitNextWidth(e, allLeafColumns);
        const nextWidths = { ...(nowWidths || {}) };
        const nextWidths$ = fromEvent(window, 'mousemove').pipe(op.takeUntil(fromEvent(window, 'mouseup')), op.map((e) => {
            const movingX = e.clientX;
            const changeWidth = movingX - startX;
            if (isLeafNode(nowColumn)) {
                nextWidths[nowColCode] = clamp(minWidth, nowWidths[nowColCode] + changeWidth, maxWidth);
            }
            else {
                // 有孩子节点
                const leafColumns = collectNodes(nowColumn.children, 'leaf-only');
                const avgChangeWidth = changeWidth / leafColumns.length;
                makeRecursiveMapper((col, range) => {
                    var _a;
                    if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                        const colCode = (_a = col === null || col === void 0 ? void 0 : col.code) !== null && _a !== void 0 ? _a : range === null || range === void 0 ? void 0 : range.startIndex;
                        nextWidths[colCode] = clamp(minWidth, nowWidths[colCode] + avgChangeWidth, maxWidth);
                    }
                    return col;
                })(nowColumn.children, startIndex);
            }
            return [nextWidths, nowColCode, nextWidths[nowColCode], movingX];
        }));
        let prevUserSelect = '';
        let docElemStyle;
        if (disableUserSelectWhenResizing) {
            docElemStyle = document.documentElement.style;
            prevUserSelect = docElemStyle.userSelect;
            docElemStyle.userSelect = 'none';
            docElemStyle.cursor = 'col-resize';
        }
        nextWidths$.subscribe({
            next: (args) => {
                if (allowColAsyncDrag) {
                    // 保存结果
                    asyncRecord = args;
                    asyncDragColHandler(args[3]);
                }
                else {
                    onChange(...args);
                }
            },
            complete() {
                if (allowColAsyncDrag && asyncRecord) {
                    onChange(...asyncRecord);
                    asyncDragColHandler(0);
                }
                if (disableUserSelectWhenResizing) {
                    docElemStyle.userSelect = prevUserSelect;
                    docElemStyle.cursor = 'unset';
                }
            },
        });
    };
    const innerTransform = (columns) => {
        // let totalWidthCalByColumn = 0;
        const leafColumns = [];
        const traverseAndGetData = makeRecursiveMapper(
        // @ts-ignore
        (col, range) => {
            var _a, _b, _c;
            if (!col) {
                return col;
            }
            const prevTitle = safeRenderHeader(col);
            const code = col.code || range.startIndex;
            const resizable = 'resizable' in col ? col === null || col === void 0 ? void 0 : col.resizable : true;
            const colWidth = (_b = (_a = widths[code]) !== null && _a !== void 0 ? _a : col.width) !== null && _b !== void 0 ? _b : safeCellPaddingWidth(size);
            if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                // totalWidthCalByColumn += colWidth;
                leafColumns.push({
                    ...(col || {}),
                    range,
                });
            }
            const resColumn = resizable && allowResize
                ? {
                    ...col,
                    width: colWidth,
                    title: (React.createElement(React.Fragment, null,
                        prevTitle,
                        React.createElement(ResizeHandle, { className: "resize-handle", onMouseDown: (e) => startResize(col, range === null || range === void 0 ? void 0 : range.startIndex, leafColumns, e) }))),
                    headerCellProps: {
                        ...col.headerCellProps,
                        style: {
                            ...(_c = col.headerCellProps) === null || _c === void 0 ? void 0 : _c.style,
                            overflow: 'visible',
                            position: 'relative',
                        },
                    },
                }
                : {
                    ...col,
                    width: colWidth,
                };
            return resColumn;
        });
        // @ts-ignore
        const resColumns = traverseAndGetData(columns);
        return resColumns;
    };
    return (pipeline) => {
        const columns = innerTransform(pipeline.getColumns());
        return pipeline.columns(columns);
    };
}
// @ts-ignore
export function AsyncDragLine({ resize, tableRef }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const update = useUpdate();
    if (resize === null || resize === void 0 ? void 0 : resize.dragLineUpdateRef) {
        resize.dragLineUpdateRef.current = update;
    }
    return (resize === null || resize === void 0 ? void 0 : resize.ability) === ColAbility.widthAndAsyncResize &&
        ((_b = (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.domHelper) === null || _b === void 0 ? void 0 : _b.artTableWrapper)
        ? ReactDOM.createPortal(React.createElement(DragLine, { ref: resize === null || resize === void 0 ? void 0 : resize.dragLineRef, tableClientX: ((_e = (_d = (_c = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _c === void 0 ? void 0 : _c.domHelper) === null || _d === void 0 ? void 0 : _d.artTableWrapper) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect) instanceof Function
                ? (_h = (_g = (_f = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _f === void 0 ? void 0 : _f.domHelper) === null || _g === void 0 ? void 0 : _g.artTableWrapper) === null || _h === void 0 ? void 0 : _h.getBoundingClientRect().x
                : 0 }), (_k = (_j = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _j === void 0 ? void 0 : _j.domHelper) === null || _k === void 0 ? void 0 : _k.artTableWrapper)
        : null;
}
// 处理dragline能力
export function useAsyncDrag(allowColAsyncDrag, dragLineUpdateRef) {
    React.useEffect(() => {
        if (allowColAsyncDrag) {
            dragLineUpdateRef.current instanceof Function &&
                dragLineUpdateRef.current();
        }
    }, [allowColAsyncDrag]);
}
function useCalAbility(columnResize, dragLineUpdateRef) {
    let ability = ColAbility.justWidth;
    if (columnResize) {
        ability =
            columnResize.async || globalAsyncDragCol
                ? ColAbility.widthAndAsyncResize
                : ColAbility.widthAndResize;
    }
    useAsyncDrag(ability === ColAbility.widthAndAsyncResize, dragLineUpdateRef);
    return ability;
}
export function useAutoWidth(props) {
    const { columnResize, columnsAutoWidth, columnsAutoWidthOpt, size, dataSource, columns, allColumns, resize, } = props;
    // @ts-ignore
    const { widths } = columnResize || {};
    useMemo(() => {
        // 满足此类条件，则需要自动计算
        if (columnsAutoWidth &&
            !widths &&
            Array.isArray(allColumns) &&
            (allColumns === null || allColumns === void 0 ? void 0 : allColumns.length) > 0 &&
            resize // 开启了width能力
        ) {
            const needCalColumns = [];
            const fixWidths = {};
            // @ts-ignore
            makeRecursiveMapper((col, range) => {
                var _a;
                if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                    if (col.width > 0 || col.enableAutoWidth === false) {
                        // @ts-ignore
                        fixWidths[(_a = col.code) !== null && _a !== void 0 ? _a : range === null || range === void 0 ? void 0 : range.startIndex] = col.width;
                    }
                    else {
                        needCalColumns.push(col);
                    }
                }
            })(allColumns);
            const calWidths = calcColumnWidths(needCalColumns, dataSource, {
                headerFontSize: 12,
                cellFontSize: 12,
                additionalWidth: safeCellPaddingWidth(size),
                ...(columnsAutoWidthOpt || {}),
            });
            resize.setWidths({
                ...calWidths,
                ...fixWidths,
            });
        }
    }, [
        dataSource,
        columns,
        widths,
        columnsAutoWidth,
        columnsAutoWidthOpt,
        size,
    ]);
}
export function useNoWidthRule(widthRef) {
    const buttonWidthRef = React.useRef({});
    const [, update] = React.useState({});
    const calOpereateButtonItemWithNoRule = React.useCallback((props) => {
        const { render, buttonIndex, record, index, splitWidth } = props;
        if (buttonWidthRef.current[buttonIndex]) {
            return buttonWidthRef.current[buttonIndex];
        }
        const element = render instanceof Function ? render(record, index) : null;
        if (element) {
            buttonWidthRef.current[buttonIndex] = true;
        }
        const measureEl = document.createElement('div');
        measureEl.style.width = 'fit-content';
        measureEl.className = 'cnc-table-measure';
        measureEl.style.cssText = `
      position: fixed;
      top: -10000px;
      left: -10000px;
      z-index: -100;
    `;
        document.body.appendChild(measureEl);
        element &&
            ReactDOM.render(element, measureEl, () => {
                var _a;
                buttonWidthRef.current[buttonIndex] =
                    measureEl.offsetWidth + splitWidth;
                const buttonValues = Object.keys(buttonWidthRef.current).map((key) => buttonWidthRef.current[key]);
                const filterValues = buttonValues.filter((val) => parseInt(val, 10) > 0);
                if (filterValues.length === buttonValues.length) {
                    if (!widthRef.current) {
                        // @ts-ignore
                        widthRef.current = {};
                    }
                    widthRef.current[operateCode] =
                        // @ts-ignore
                        ((_a = widthRef.current[operateCode]) !== null && _a !== void 0 ? _a : 0) +
                            // @ts-ignore
                            filterValues.reduce((ret, val) => ret + val, 0);
                    document.body.removeChild(measureEl);
                    update({});
                }
            });
    }, []);
    return {
        calOpereateButtonItemWithNoRule,
    };
}
export function useColumsResize(props) {
    const { columnResize, columnsAutoWidth, size } = props;
    if (!isDef(columnResize) && !columnsAutoWidth) {
        return;
    }
    const update = useUpdate();
    const dragLineUpdateRef = React.useRef();
    const ability = useCalAbility(columnResize, dragLineUpdateRef);
    // @ts-ignore
    const { widths, defaultWidths = {} } = columnResize || {};
    const widthsRef = React.useRef(widths || defaultWidths || {});
    const setWidths = React.useCallback((ws) => {
        widthsRef.current = ws;
    }, []);
    const setWidth = React.useCallback((key, val) => {
        if (!isPlainObject(widthsRef.current)) {
            widthsRef.current = {};
        }
        widthsRef.current[key] = val;
    }, []);
    const { calOpereateButtonItemWithNoRule } = useNoWidthRule(widthsRef);
    useWillUpdate(() => {
        setWidths(widths);
    }, [widths]);
    const dragLineRef = React.useRef();
    return {
        widthsRef,
        setWidth,
        setWidths,
        calOpereateButtonItemWithNoRule,
        size,
        dragLineRef,
        columnResize: columnResize,
        columnsAutoWidth,
        ability,
        update,
        dragLineUpdateRef,
    };
}
function getInitNextWidth(e, leafColumns) {
    var _a;
    const tableDom = findTarget(e.target, (el) => el.tagName === 'TABLE');
    if (((_a = tableDom === null || tableDom === void 0 ? void 0 : tableDom.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        const nextWidth = {};
        // @ts-ignore
        Array.from(tableDom === null || tableDom === void 0 ? void 0 : tableDom.children).forEach((_dom) => {
            var _a;
            if ((_dom === null || _dom === void 0 ? void 0 : _dom.tagName) === 'COLGROUP' && ((_a = _dom === null || _dom === void 0 ? void 0 : _dom.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                Array.from(_dom === null || _dom === void 0 ? void 0 : _dom.children).forEach((colDom, index) => {
                    var _a, _b, _c;
                    if (colDom) {
                        const matchColum = leafColumns[index];
                        if (matchColum) {
                            const colCode = (_a = matchColum === null || matchColum === void 0 ? void 0 : matchColum.code) !== null && _a !== void 0 ? _a : (_b = matchColum === null || matchColum === void 0 ? void 0 : matchColum.range) === null || _b === void 0 ? void 0 : _b.startIndex;
                            nextWidth[colCode] =
                                colDom.clientWidth > 0
                                    ? colDom.clientWidth
                                    : // chrome 浏览器有的版本
                                        parseInt((_c = colDom === null || colDom === void 0 ? void 0 : colDom.style) === null || _c === void 0 ? void 0 : _c.width, 10);
                        }
                    }
                });
            }
        });
        return nextWidth;
    }
    return {};
}
