import { Checkbox, CheckboxGroup, DatePicker2, Input, NumberPicker, Radio, RadioGroup, RangeDatePicker, RangeTimePicker, Select, TextArea, TimePicker2, Transfer, Range, } from '@/form/fusion-compatible';
import { Switch } from '@/form/switch';
import { CnIcon } from '@/form/cn-icon';
import { CnBankInput } from '@/form/cn-bank-input';
import { CnNumberPicker, CnRangeNumberPicker, CnPercentInput, } from '@/form/cn-number-picker';
import { FormItem } from '@/form/form-item';
import { CnFormItem } from '@/form/cn-form-item';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnFormGrid } from '@/form/cn-form-grid';
import { CnSwitch } from '@/form/cn-switch';
import { Submit } from '@/form/submit';
import { Reset } from '@/form/reset';
import { CnFormSingleCol } from '@/form/cn-form-single-col';
import { CnCard, CnCardSubCard, CnCardSubAreaCard } from '@/form/cn-card';
import { CnArrayItems } from '@/form/cn-array-items';
import { CnArraySubAreaCard } from '@/form/cn-array-sub-area-card';
import { CnArrayTable, CnArrayTableColumn, CnArrayTableAddition, CnArrayTableIndex, CnArrayTableRemove, CnArrayTableMoveUp, CnArrayTableMoveDown, CnArrayTableSortHandle, } from '@/form/cn-array-table';
import { CnInput, CnInputTextArea } from '@/form/cn-input';
import { CnAsyncSelect, CnAsyncSelect as CnSelect, CnAsyncSelectAutoComplete, CnAsyncSelectAutoComplete as CnSelectAutoComplete, } from '@/form/cn-async-select';
import { CnRadio, CnRadioGroup } from '@/form/cn-radio-group';
import { CnCheckbox, CnCheckboxGroup } from '@/form/cn-checkbox';
import { CnComplexRadio, CnComplexRadioGroup } from '@/form/cn-complex-radio';
import { CnComplexCheckbox, CnComplexCheckboxGroup } from '@/form/cn-complex-checkbox';
import { CnRangeTimePicker2, CnTimePicker2, CnRangeTimePickerPro, CnTimePickerPro, } from '@/form/cn-time-picker-pro';
import { CnDatePicker2, CnMonthPicker2, CnQuarterPicker2, CnRangeDatePicker2, CnWeekPicker2, CnYearPicker2, CnDatePickerPro, CnMonthPickerPro, CnQuarterPickerPro, CnRangeDatePickerPro, CnWeekPickerPro, CnYearPickerPro, } from '@/form/cn-date-picker-pro';
import { CnTreeSelect } from '@/form/cn-tree-select';
import { CnCascaderSelect } from '@/form/cn-cascader-select';
import { CnFormStep, CnFormStepItem } from '@/form/cn-form-step';
import { CnOSSUpload } from '@/form/cn-oss-upload';
import { CnEmployeeSelect } from '@/form/cn-employee-select';
import { CnDepartmentSelect } from '@/form/cn-department-select';
import { CnCurrencySelect } from '@/form/cn-currency-select';
import { CnCurrencyAmountNumberPicker } from '@/form/cn-currency-amount-number-picker';
import { CnTree } from '@/form/cn-tree';
import { CnRating } from '@/form/cn-rating';
import { CnFormTab, CnFormTabItem } from '@/form/cn-form-tab';
import { CnTable } from '@/form/cn-table';
import { CnTransfer } from '@/form/cn-transfer';
export const componentMap = {
    Input,
    FormItem,
    Checkbox,
    CheckboxGroup,
    Radio,
    RadioGroup,
    TextArea,
    TimePicker2,
    NumberPicker,
    RangeTimePicker,
    DatePicker2,
    RangeDatePicker,
    Switch,
    Select,
    Range,
    Transfer,
    Submit,
    Reset,
    // CnArrayTable
    CnArrayItems,
    CnArraySubAreaCard,
    CnArrayTable,
    CnArrayTableColumn,
    CnArrayTableAddition,
    CnArrayTableIndex,
    CnArrayTableRemove,
    CnArrayTableMoveUp,
    CnArrayTableMoveDown,
    CnArrayTableSortHandle,
    // 一般组件
    CnNumberPicker,
    CnRangeNumberPicker,
    CnPercentInput,
    CnFormLayout,
    CnFormGrid,
    CnTreeSelect,
    CnCascaderSelect,
    CnFormSingleCol,
    CnFormItem,
    CnIcon,
    CnBankInput,
    CnSwitch,
    CnInput,
    CnInputTextArea,
    CnRadio,
    CnRadioGroup,
    CnComplexRadio,
    CnComplexRadioGroup,
    CnTimePicker2,
    CnRangeTimePicker2,
    CnTimePickerPro,
    CnRangeTimePickerPro,
    CnSelect,
    CnSelectAutoComplete,
    CnAsyncSelect,
    CnAsyncSelectAutoComplete,
    CnCheckbox,
    CnCheckboxGroup,
    CnComplexCheckbox,
    CnComplexCheckboxGroup,
    CnDatePicker2,
    CnMonthPicker2,
    CnYearPicker2,
    CnWeekPicker2,
    CnQuarterPicker2,
    CnRangeDatePicker2,
    CnDatePickerPro,
    CnMonthPickerPro,
    CnYearPickerPro,
    CnWeekPickerPro,
    CnQuarterPickerPro,
    CnRangeDatePickerPro,
    CnCard,
    CnCardSubCard,
    CnCardSubAreaCard,
    CnOSSUpload,
    CnEmployeeSelect,
    CnDepartmentSelect,
    CnCurrencySelect,
    CnCurrencyAmountNumberPicker,
    CnFormStep,
    CnFormStepItem,
    CnTree,
    CnRating,
    CnFormTab,
    CnFormTabItem,
    CnTable,
    CnTransfer,
};
