import * as React from 'react';
import { Timeline as NextTimeline } from '@fusion/lib';
const { Item: NextTimelineItem } = NextTimeline;
export const Timeline = React.forwardRef((props, ref) => {
    return (React.createElement(NextTimeline, { "data-name": "CnTimeline", ref: ref, ...props }));
});
Timeline.displayName = 'Timeline';
/**
 * @deprecated
 * 建议使用 TimelineItem
 */
Timeline.Item = NextTimelineItem;
export const TimelineItem = NextTimelineItem;
TimelineItem.displayName = 'TimelineItem';
